// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$-shape-1-copy-name: 'Shape_1_copy';
$-shape-1-copy-x: 110px;
$-shape-1-copy-y: 121px;
$-shape-1-copy-offset-x: -110px;
$-shape-1-copy-offset-y: -121px;
$-shape-1-copy-width: 34px;
$-shape-1-copy-height: 23px;
$-shape-1-copy-total-width: 270px;
$-shape-1-copy-total-height: 242px;
$-shape-1-copy-image: '../images/sprite.png';
$-shape-1-copy: (110px, 121px, -110px, -121px, 34px, 23px, 270px, 242px, '../images/sprite.png', 'Shape_1_copy', );
$accept-name: 'accept';
$accept-x: 252px;
$accept-y: 92px;
$accept-offset-x: -252px;
$accept-offset-y: -92px;
$accept-width: 13px;
$accept-height: 10px;
$accept-total-width: 270px;
$accept-total-height: 242px;
$accept-image: '../images/sprite.png';
$accept: (252px, 92px, -252px, -92px, 13px, 10px, 270px, 242px, '../images/sprite.png', 'accept', );
$arrow-back-name: 'arrow-back';
$arrow-back-x: 252px;
$arrow-back-y: 0px;
$arrow-back-offset-x: -252px;
$arrow-back-offset-y: 0px;
$arrow-back-width: 18px;
$arrow-back-height: 15px;
$arrow-back-total-width: 270px;
$arrow-back-total-height: 242px;
$arrow-back-image: '../images/sprite.png';
$arrow-back: (252px, 0px, -252px, 0px, 18px, 15px, 270px, 242px, '../images/sprite.png', 'arrow-back', );
$checkbox-checked-name: 'checkbox-checked';
$checkbox-checked-x: 252px;
$checkbox-checked-y: 106px;
$checkbox-checked-offset-x: -252px;
$checkbox-checked-offset-y: -106px;
$checkbox-checked-width: 12px;
$checkbox-checked-height: 9px;
$checkbox-checked-total-width: 270px;
$checkbox-checked-total-height: 242px;
$checkbox-checked-image: '../images/sprite.png';
$checkbox-checked: (252px, 106px, -252px, -106px, 12px, 9px, 270px, 242px, '../images/sprite.png', 'checkbox-checked', );
$close-name: 'close';
$close-x: 165px;
$close-y: 148px;
$close-offset-x: -165px;
$close-offset-y: -148px;
$close-width: 27px;
$close-height: 27px;
$close-total-width: 270px;
$close-total-height: 242px;
$close-image: '../images/sprite.png';
$close: (165px, 148px, -165px, -148px, 27px, 27px, 270px, 242px, '../images/sprite.png', 'close', );
$delete-name: 'delete';
$delete-x: 252px;
$delete-y: 60px;
$delete-offset-x: -252px;
$delete-offset-y: -60px;
$delete-width: 12px;
$delete-height: 12px;
$delete-total-width: 270px;
$delete-total-height: 242px;
$delete-image: '../images/sprite.png';
$delete: (252px, 60px, -252px, -60px, 12px, 12px, 270px, 242px, '../images/sprite.png', 'delete', );
$icon-accept-green-name: 'icon-accept-green';
$icon-accept-green-x: 149px;
$icon-accept-green-y: 108px;
$icon-accept-green-offset-x: -149px;
$icon-accept-green-offset-y: -108px;
$icon-accept-green-width: 37px;
$icon-accept-green-height: 35px;
$icon-accept-green-total-width: 270px;
$icon-accept-green-total-height: 242px;
$icon-accept-green-image: '../images/sprite.png';
$icon-accept-green: (149px, 108px, -149px, -108px, 37px, 35px, 270px, 242px, '../images/sprite.png', 'icon-accept-green', );
$icon-accept-white-name: 'icon-accept-white';
$icon-accept-white-x: 94px;
$icon-accept-white-y: 54px;
$icon-accept-white-offset-x: -94px;
$icon-accept-white-offset-y: -54px;
$icon-accept-white-width: 37px;
$icon-accept-white-height: 35px;
$icon-accept-white-total-width: 270px;
$icon-accept-white-total-height: 242px;
$icon-accept-white-image: '../images/sprite.png';
$icon-accept-white: (94px, 54px, -94px, -54px, 37px, 35px, 270px, 242px, '../images/sprite.png', 'icon-accept-white', );
$icon-basket-green-name: 'icon-basket-green';
$icon-basket-green-x: 44px;
$icon-basket-green-y: 202px;
$icon-basket-green-offset-x: -44px;
$icon-basket-green-offset-y: -202px;
$icon-basket-green-width: 37px;
$icon-basket-green-height: 35px;
$icon-basket-green-total-width: 270px;
$icon-basket-green-total-height: 242px;
$icon-basket-green-image: '../images/sprite.png';
$icon-basket-green: (44px, 202px, -44px, -202px, 37px, 35px, 270px, 242px, '../images/sprite.png', 'icon-basket-green', );
$icon-basket-mini-name: 'icon-basket-mini';
$icon-basket-mini-x: 204px;
$icon-basket-mini-y: 144px;
$icon-basket-mini-offset-x: -204px;
$icon-basket-mini-offset-y: -144px;
$icon-basket-mini-width: 40px;
$icon-basket-mini-height: 40px;
$icon-basket-mini-total-width: 270px;
$icon-basket-mini-total-height: 242px;
$icon-basket-mini-image: '../images/sprite.png';
$icon-basket-mini: (204px, 144px, -204px, -144px, 40px, 40px, 270px, 242px, '../images/sprite.png', 'icon-basket-mini', );
$icon-basket-white-name: 'icon-basket-white';
$icon-basket-white-x: 85px;
$icon-basket-white-y: 202px;
$icon-basket-white-offset-x: -85px;
$icon-basket-white-offset-y: -202px;
$icon-basket-white-width: 37px;
$icon-basket-white-height: 35px;
$icon-basket-white-total-width: 270px;
$icon-basket-white-total-height: 242px;
$icon-basket-white-image: '../images/sprite.png';
$icon-basket-white: (85px, 202px, -85px, -202px, 37px, 35px, 270px, 242px, '../images/sprite.png', 'icon-basket-white', );
$icon-basket-name: 'icon-basket';
$icon-basket-x: 204px;
$icon-basket-y: 0px;
$icon-basket-offset-x: -204px;
$icon-basket-offset-y: 0px;
$icon-basket-width: 44px;
$icon-basket-height: 44px;
$icon-basket-total-width: 270px;
$icon-basket-total-height: 242px;
$icon-basket-image: '../images/sprite.png';
$icon-basket: (204px, 0px, -204px, 0px, 44px, 44px, 270px, 242px, '../images/sprite.png', 'icon-basket', );
$icon-calendar-name: 'icon-calendar';
$icon-calendar-x: 208px;
$icon-calendar-y: 202px;
$icon-calendar-offset-x: -208px;
$icon-calendar-offset-y: -202px;
$icon-calendar-width: 18px;
$icon-calendar-height: 18px;
$icon-calendar-total-width: 270px;
$icon-calendar-total-height: 242px;
$icon-calendar-image: '../images/sprite.png';
$icon-calendar: (208px, 202px, -208px, -202px, 18px, 18px, 270px, 242px, '../images/sprite.png', 'icon-calendar', );
$icon-close-name: 'icon-close';
$icon-close-x: 252px;
$icon-close-y: 76px;
$icon-close-offset-x: -252px;
$icon-close-offset-y: -76px;
$icon-close-width: 12px;
$icon-close-height: 12px;
$icon-close-total-width: 270px;
$icon-close-total-height: 242px;
$icon-close-image: '../images/sprite.png';
$icon-close: (252px, 76px, -252px, -76px, 12px, 12px, 270px, 242px, '../images/sprite.png', 'icon-close', );
$icon-dilivery-name: 'icon-dilivery';
$icon-dilivery-x: 110px;
$icon-dilivery-y: 94px;
$icon-dilivery-offset-x: -110px;
$icon-dilivery-offset-y: -94px;
$icon-dilivery-width: 34px;
$icon-dilivery-height: 23px;
$icon-dilivery-total-width: 270px;
$icon-dilivery-total-height: 242px;
$icon-dilivery-image: '../images/sprite.png';
$icon-dilivery: (110px, 94px, -110px, -94px, 34px, 23px, 270px, 242px, '../images/sprite.png', 'icon-dilivery', );
$icon-fb-color-name: 'icon-fb-color';
$icon-fb-color-x: 94px;
$icon-fb-color-y: 0px;
$icon-fb-color-offset-x: -94px;
$icon-fb-color-offset-y: 0px;
$icon-fb-color-width: 51px;
$icon-fb-color-height: 50px;
$icon-fb-color-total-width: 270px;
$icon-fb-color-total-height: 242px;
$icon-fb-color-image: '../images/sprite.png';
$icon-fb-color: (94px, 0px, -94px, 0px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-fb-color', );
$icon-fb-name: 'icon-fb';
$icon-fb-x: 0px;
$icon-fb-y: 94px;
$icon-fb-offset-x: 0px;
$icon-fb-offset-y: -94px;
$icon-fb-width: 51px;
$icon-fb-height: 50px;
$icon-fb-total-width: 270px;
$icon-fb-total-height: 242px;
$icon-fb-image: '../images/sprite.png';
$icon-fb: (0px, 94px, 0px, -94px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-fb', );
$icon-google-color-name: 'icon-google-color';
$icon-google-color-x: 55px;
$icon-google-color-y: 94px;
$icon-google-color-offset-x: -55px;
$icon-google-color-offset-y: -94px;
$icon-google-color-width: 51px;
$icon-google-color-height: 50px;
$icon-google-color-total-width: 270px;
$icon-google-color-total-height: 242px;
$icon-google-color-image: '../images/sprite.png';
$icon-google-color: (55px, 94px, -55px, -94px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-google-color', );
$icon-google-name: 'icon-google';
$icon-google-x: 149px;
$icon-google-y: 0px;
$icon-google-offset-x: -149px;
$icon-google-offset-y: 0px;
$icon-google-width: 51px;
$icon-google-height: 50px;
$icon-google-total-width: 270px;
$icon-google-total-height: 242px;
$icon-google-image: '../images/sprite.png';
$icon-google: (149px, 0px, -149px, 0px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-google', );
$icon-inst-color-name: 'icon-inst-color';
$icon-inst-color-x: 149px;
$icon-inst-color-y: 54px;
$icon-inst-color-offset-x: -149px;
$icon-inst-color-offset-y: -54px;
$icon-inst-color-width: 51px;
$icon-inst-color-height: 50px;
$icon-inst-color-total-width: 270px;
$icon-inst-color-total-height: 242px;
$icon-inst-color-image: '../images/sprite.png';
$icon-inst-color: (149px, 54px, -149px, -54px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-inst-color', );
$icon-inst-name: 'icon-inst';
$icon-inst-x: 0px;
$icon-inst-y: 148px;
$icon-inst-offset-x: 0px;
$icon-inst-offset-y: -148px;
$icon-inst-width: 51px;
$icon-inst-height: 50px;
$icon-inst-total-width: 270px;
$icon-inst-total-height: 242px;
$icon-inst-image: '../images/sprite.png';
$icon-inst: (0px, 148px, 0px, -148px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-inst', );
$icon-mail-name: 'icon-mail';
$icon-mail-x: 252px;
$icon-mail-y: 42px;
$icon-mail-offset-x: -252px;
$icon-mail-offset-y: -42px;
$icon-mail-width: 18px;
$icon-mail-height: 14px;
$icon-mail-total-width: 270px;
$icon-mail-total-height: 242px;
$icon-mail-image: '../images/sprite.png';
$icon-mail: (252px, 42px, -252px, -42px, 18px, 14px, 270px, 242px, '../images/sprite.png', 'icon-mail', );
$icon-map-name: 'icon-map';
$icon-map-x: 252px;
$icon-map-y: 19px;
$icon-map-offset-x: -252px;
$icon-map-offset-y: -19px;
$icon-map-width: 14px;
$icon-map-height: 19px;
$icon-map-total-width: 270px;
$icon-map-total-height: 242px;
$icon-map-image: '../images/sprite.png';
$icon-map: (252px, 19px, -252px, -19px, 14px, 19px, 270px, 242px, '../images/sprite.png', 'icon-map', );
$icon-pay-green-name: 'icon-pay-green';
$icon-pay-green-x: 126px;
$icon-pay-green-y: 202px;
$icon-pay-green-offset-x: -126px;
$icon-pay-green-offset-y: -202px;
$icon-pay-green-width: 37px;
$icon-pay-green-height: 31px;
$icon-pay-green-total-width: 270px;
$icon-pay-green-total-height: 242px;
$icon-pay-green-image: '../images/sprite.png';
$icon-pay-green: (126px, 202px, -126px, -202px, 37px, 31px, 270px, 242px, '../images/sprite.png', 'icon-pay-green', );
$icon-pay-white-name: 'icon-pay-white';
$icon-pay-white-x: 167px;
$icon-pay-white-y: 202px;
$icon-pay-white-offset-x: -167px;
$icon-pay-white-offset-y: -202px;
$icon-pay-white-width: 37px;
$icon-pay-white-height: 31px;
$icon-pay-white-total-width: 270px;
$icon-pay-white-total-height: 242px;
$icon-pay-white-image: '../images/sprite.png';
$icon-pay-white: (167px, 202px, -167px, -202px, 37px, 31px, 270px, 242px, '../images/sprite.png', 'icon-pay-white', );
$icon-personal-color-name: 'icon-personal-color';
$icon-personal-color-x: 204px;
$icon-personal-color-y: 48px;
$icon-personal-color-offset-x: -204px;
$icon-personal-color-offset-y: -48px;
$icon-personal-color-width: 44px;
$icon-personal-color-height: 44px;
$icon-personal-color-total-width: 270px;
$icon-personal-color-total-height: 242px;
$icon-personal-color-image: '../images/sprite.png';
$icon-personal-color: (204px, 48px, -204px, -48px, 44px, 44px, 270px, 242px, '../images/sprite.png', 'icon-personal-color', );
$icon-personal-name: 'icon-personal';
$icon-personal-x: 204px;
$icon-personal-y: 96px;
$icon-personal-offset-x: -204px;
$icon-personal-offset-y: -96px;
$icon-personal-width: 44px;
$icon-personal-height: 44px;
$icon-personal-total-width: 270px;
$icon-personal-total-height: 242px;
$icon-personal-image: '../images/sprite.png';
$icon-personal: (204px, 96px, -204px, -96px, 44px, 44px, 270px, 242px, '../images/sprite.png', 'icon-personal', );
$icon-pipette-name: 'icon-pipette';
$icon-pipette-x: 165px;
$icon-pipette-y: 179px;
$icon-pipette-offset-x: -165px;
$icon-pipette-offset-y: -179px;
$icon-pipette-width: 19px;
$icon-pipette-height: 18px;
$icon-pipette-total-width: 270px;
$icon-pipette-total-height: 242px;
$icon-pipette-image: '../images/sprite.png';
$icon-pipette: (165px, 179px, -165px, -179px, 19px, 18px, 270px, 242px, '../images/sprite.png', 'icon-pipette', );
$icon-play-name: 'icon-play';
$icon-play-x: 0px;
$icon-play-y: 0px;
$icon-play-offset-x: 0px;
$icon-play-offset-y: 0px;
$icon-play-width: 90px;
$icon-play-height: 90px;
$icon-play-total-width: 270px;
$icon-play-total-height: 242px;
$icon-play-image: '../images/sprite.png';
$icon-play: (0px, 0px, 0px, 0px, 90px, 90px, 270px, 242px, '../images/sprite.png', 'icon-play', );
$icon-print-name: 'icon-print';
$icon-print-x: 230px;
$icon-print-y: 202px;
$icon-print-offset-x: -230px;
$icon-print-offset-y: -202px;
$icon-print-width: 18px;
$icon-print-height: 18px;
$icon-print-total-width: 270px;
$icon-print-total-height: 242px;
$icon-print-image: '../images/sprite.png';
$icon-print: (230px, 202px, -230px, -202px, 18px, 18px, 270px, 242px, '../images/sprite.png', 'icon-print', );
$icon-vk-color-name: 'icon-vk-color';
$icon-vk-color-x: 55px;
$icon-vk-color-y: 148px;
$icon-vk-color-offset-x: -55px;
$icon-vk-color-offset-y: -148px;
$icon-vk-color-width: 51px;
$icon-vk-color-height: 50px;
$icon-vk-color-total-width: 270px;
$icon-vk-color-total-height: 242px;
$icon-vk-color-image: '../images/sprite.png';
$icon-vk-color: (55px, 148px, -55px, -148px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-vk-color', );
$icon-vk-name: 'icon-vk';
$icon-vk-x: 110px;
$icon-vk-y: 148px;
$icon-vk-offset-x: -110px;
$icon-vk-offset-y: -148px;
$icon-vk-width: 51px;
$icon-vk-height: 50px;
$icon-vk-total-width: 270px;
$icon-vk-total-height: 242px;
$icon-vk-image: '../images/sprite.png';
$icon-vk: (110px, 148px, -110px, -148px, 51px, 50px, 270px, 242px, '../images/sprite.png', 'icon-vk', );
$mark-plus-name: 'mark-plus';
$mark-plus-x: 0px;
$mark-plus-y: 202px;
$mark-plus-offset-x: 0px;
$mark-plus-offset-y: -202px;
$mark-plus-width: 40px;
$mark-plus-height: 40px;
$mark-plus-total-width: 270px;
$mark-plus-total-height: 242px;
$mark-plus-image: '../images/sprite.png';
$mark-plus: (0px, 202px, 0px, -202px, 40px, 40px, 270px, 242px, '../images/sprite.png', 'mark-plus', );
$spritesheet-width: 270px;
$spritesheet-height: 242px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($-shape-1-copy, $accept, $arrow-back, $checkbox-checked, $close, $delete, $icon-accept-green, $icon-accept-white, $icon-basket-green, $icon-basket-mini, $icon-basket-white, $icon-basket, $icon-calendar, $icon-close, $icon-dilivery, $icon-fb-color, $icon-fb, $icon-google-color, $icon-google, $icon-inst-color, $icon-inst, $icon-mail, $icon-map, $icon-pay-green, $icon-pay-white, $icon-personal-color, $icon-personal, $icon-pipette, $icon-play, $icon-print, $icon-vk-color, $icon-vk, $mark-plus, );
$spritesheet: (270px, 242px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
