@import "base/variables";
@import "base/functions";
@import "base/mixins";

.search {
  position: relative;
  z-index: 20;
  margin-right: 35px;

  @media (max-width: 1240px) {
    position: static;
  }
  @media (max-width: 600px) {
    margin-right: 10px;
    padding-top: calc(50vh - 20px);
  }
  @media (max-width: 375px) {
    margin-right: 5px;
  }

  &.error {
    .search-icon {
      color: #ff0000;
      background-image: url(../images/search-icon-error.svg);
    }
  }

  &-error {
    font-size: 12px;
    color: #a9a5a5;
    text-transform: uppercase;
    width: 100%;
    background-color: #fff;
    @media (max-width: 1240px) {
      right: 130px;
    }
    @media (max-width: 600px) {
      right: 15px;
      width: 230px;
      font-size: 9px;
    }
  }

  &-icon {
    width: 90px;
    height: 24px;
    background-image: url(../images/search-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    border: none;
    outline: none;
    background-color: transparent;

    @media (max-width: 1240px) {
      width: 24px;
      background-position: left 0 bottom -3px;
    }

    &:hover {
      //color: $green;
      //background-image: url(../images/search-icon-focus.svg);

      .search-name {
        //color: rgba(255, 255, 255, 0.8) !important;
        opacity: 0.8;
      }
    }

    &.active {
      opacity: 0.8;
      // background-image: url(../images/search-icon-focus.svg);
    }

    span {
      padding-left: 30px;
      text-align: left;
      display: block;
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      font-size: rem(13px);
      text-transform: uppercase;
      font-weight: $font-weight--semibold;
      cursor: pointer;

      @media (max-width: 1240px) {
        display: none;
      }
    }
  }

  &-wrapper {
    box-shadow: 4px 4px 8px rgba(56, 56, 56, 0.2);
    border-radius: 3px;
    position: absolute !important;
    top: 35px;
    left: -5px;
    display: none;

    @media (max-width: 1240px) {
      right: 13px;
      left: auto;
    }

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      box-shadow: 0 0.5px 0.5px rgba(56, 56, 56, 0.2);
      transform: rotate(-135deg);
      position: absolute;
      top: -5px;
      left: 22px;

      @media (max-width: 1240px) {
        left: auto;
        right: 30%;
      }

      @media (max-width: 600px) {
        right: 80px;
      }
    }
  }

  &-input {
    color: #333a40;
    padding: 10px 20px;
    width: 430px;
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px !important;

    @media (max-width: 600px) {
      width: calc(100vw - 26px);
    }
  }

  &-btn {
    padding: 10px 5px;
    text-transform: uppercase;
    background-color: transparent;
    color: $green;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    position: absolute !important;
    top: 1px;
    right: 40px;
    border: none;
    outline: none;
    background-color: transparent;
  }

  &-x {
    width: 24px;
    height: 24px;
    background-image: url(../images/x.svg);
    background-size: 100%;
    position: absolute;
    top: 7px;
    right: 10px;
    border: none;
    outline: none;
    background-color: transparent;
  }
}

.search-items {
  margin: 5px 0;
  position: absolute;
  width: 430px;
  background-color: $body-bg-color;
  top: 70px;
  left: -5px;
  padding: 0 10px 0 20px;
  overflow-y: auto;

  @media (max-width: 1240px) {
    right: 13px;
    left: auto;
  }
  @media (max-width: 600px) {
    top: 69px;
    width: calc(100vw - 26px);
    height: calc(50vh - 62px) !important;
  }
  @media (max-width: 375px) {
    width: 100%;
    padding: 0 5px;
    margin: 5px -15px;
  }
}

.search_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;
  text-decoration: none;
  padding: 5px 0 5px 5px;
  flex-wrap: nowrap;

  &:hover {
    background-color: $white;
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
    text-decoration: none;
  }

  &-img {
    width: 100px;
    margin-right: 10px;
    flex-shrink: 0;

    @media (max-width: 425px) {
      width: 80px;
    }
    @media (max-width: 375px) {
      width: 70px;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    color: $base-color;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    width: 400px;

    @media (max-width: 560px) {
      font-size: 12px;
      margin-right: 10px;
    }
  }

  &-price {
    color: $base-color;
    min-width: 80px;
    font-size: 14px;

    @media (max-width: 560px) {
      font-size: 13px;
      min-width: 75px;
    }
  }
}

.search_ims-empty {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;

  span {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}
