.cookie-policy {
  padding: 42px 0 71px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: #404040;
  width: 100%;
  border-top: 1px solid #4a4a4a;
  display: none;
  @media (max-width: 1200px){
    padding: 20px 0 ;
  }

  .wrapper{
    max-width: 1220px;
  }

  &-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 650px) {
      flex-wrap: wrap;
    }
  }

  &-text {
    margin-right: 20px;
    padding-top: 3px;
    font-size: rem(16px);
    font-weight: 600;
    line-height: 1.3;
    color: #ffffff;
    max-width: 791px;
    letter-spacing: 0.008em;
    @media (max-width: 650px) {
      margin-right: 0;
      padding-top: 0;
    }
    a{
      color: lighten(#1C4A9F, 20%);
    }

    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &-button {
    flex-grow: 0;
    flex-basis: 167px;

    @media (max-width: 650px) {
      margin-top: 15px;
    }

    button {
      padding: 10px 16px;
      display: block;
      width: 100%;
      line-height: 1;
      background-color: lighten(#1C4A9F, 20%);
      border: 0;
      border-radius: 8px;
      @include transition();

      @media (max-width: 650px) {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      span {
        color: $white;
        font: {
          size: 14px;
          weight: 600;
        }
        line-height: 1.3;
        letter-spacing: 0.008em;
        text-transform: uppercase;
      }

      &:hover {
        background-color: lighten(#1C4A9F, 5%);
      }
      &:active {
        background-color: $link-color--active;
      }
    }
  }

  @media print {
    display: none !important;
  }
}