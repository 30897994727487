body {
  background: {
    color: $body-bg-color;
  }
  font: {
    family: $baseFonts;
    size: $font-size;
    weight: $font-weight--regular;
  }
  line-height: normal;

  &.overflow {
    overflow: hidden;
  }
}

.only-desktop {
  display: block;
  @media (max-width: 550px) {
    display: none;
  }
}

.only-mobile {
  display: none;
  @media (max-width: 550px) {
    display: block;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: auto;

  & > .content {
    flex: 1 0 auto;
    position: relative;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
  }
}

.wrapper {
  margin: 0 auto;
  padding: $wrapper-padding;
  min-width: $wrapper-min-width;
  max-width: $wrapper-max-width;
}

[hidden] {
  display: none !important;
}

.counters {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
  border: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  justify-content: center;
  align-items: center;
  display: none;

  &.active {
    display: flex;
  }

  span {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/circles-loader.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    animation-name: preloader;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes preloader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

span.icon {
  display: inline-block;

  &.icon-map {
    @include sprite($icon-map);
  }

  &.icon-mail {
    @include sprite($icon-mail);
    margin-right: 10px;
  }

  &.icon-basket {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Ccircle cx='8.19902' cy='20.9285' r='1' fill='%2331302F' stroke='%2331302F' stroke-width='1.54306'/%3E%3Ccircle cx='15.9473' cy='20.9285' r='1' fill='%2331302F' stroke='%2331302F' stroke-width='1.54306'/%3E%3Cpath d='M2.00004 6.20181L4.10921 6.20181C4.59194 6.20181 5.00576 6.54665 5.09281 7.02147L5.56053 9.57254M5.56053 9.57254L6.83083 16.5011C6.91789 16.9759 7.33171 17.3208 7.81444 17.3208L17.2417 17.3208C17.7132 17.3208 18.1207 16.9914 18.2195 16.5303L19.4513 10.7821C19.5847 10.1596 19.1101 9.57254 18.4735 9.57254L5.56053 9.57254Z' stroke='%2331302F' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  }

  &.icon-personal {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Ccircle cx='12' cy='9' r='4' stroke='%2331302F' stroke-width='2'/%3E%3Cpath d='M4.0001 20.0691C8.38026 15.6889 15.4819 15.6889 19.8621 20.0691' stroke='%2331302F' stroke-width='2' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.icon-pipette {
    @include sprite($icon-pipette);
  }

  &.icon-basket-mini {
    @include sprite($icon-basket-mini);
  }

  &.icon-vk {
    background-image: url(../images/vector/vk.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/vk-black.svg);

      &:hover {
        background-image: url(../images/vector/vk-hover.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/vk-hover.svg);
    }
  }

  &.icon-fb {
    background-image: url(../images/vector/fb.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/fb-black.svg);

      &:hover {
        background-image: url(../images/vector/fb-hover.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/fb-hover.svg);
    }
  }

  &.icon-whatsapp {
    background-image: url(../images/vector/whatsapp-white.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/whatsapp-black.svg);

      &:hover {
        background-image: url(../images/vector/whatsapp-white-hover.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/whatsapp-white-hover.svg);
    }
  }

  &.icon-inst {
    background-image: url(../images/vector/inst.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &:hover {
      background-image: url(../images/vector/inst-hover.svg);
    }
  }

  &.icon-pin {
    background-image: url(../images/vector/pin.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/pin-black.svg);

      &:hover {
        background-image: url(../images/vector/pin-hover.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/pin-hover.svg);
    }
  }

  &.icon-dzen {
    background-image: url(../images/vector/dzen.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/dzen-black.svg);

      &:hover {
        background-image: url(../images/vector/dzen.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/dzen-black.svg);
    }
  }

  &.icon-youtube {
    background-image: url(../images/vector/youtube.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/youtube-black.svg);

      &:hover {
        background-image: url(../images/vector/youtube-hover.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/youtube-hover.svg);
    }
  }

  &.icon-google {
    @include sprite($icon-google);
  }

  &.icon-vk-color {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/vector/vk-icon.svg);

    background-position: center center;
    background-repeat: no-repeat;
  }

  &.icon-fb-color {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/fb-black.svg);
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.icon-tg {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/vector/tg.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;

    &-black {
      background-image: url(../images/vector/tg-black.svg);

      &:hover {
        background-image: url(../images/vector/tg-hover.svg);
      }
    }

    &:hover {
      background-image: url(../images/vector/tg-hover.svg);
    }
  }

  &.icon-wa {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/vector/wa-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.icon-inst-color {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/inst-black.svg);
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.icon-whatsapp-color {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/whatsapp-black.svg);
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.icon-google-color {
    @include sprite($icon-google-color);
  }

  &.icon-dilivery {
    @include sprite($icon-dilivery);
  }

  &.icon-like {
    width: 30px;
    height: 30px;
    display: block;
    background-image: url(../images/vector/like-new.svg);
    background-position: center center;
    background-repeat: no-repeat;

    &:hover{
      background-image: url(../images/vector/like-new-active.svg);
    }
    &:active{
      background-image: url(../images/vector/like-new-active.svg);
    }
    &.active{
      background-image: url(../images/vector/like-new-active.svg);
    }
  }

  &.icon-dislike {
    width: 25px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/dislike.svg);
    background-position: center center;
    background-repeat: no-repeat;
    @include transition();

    &:hover{
      background-image: url(../images/vector/dislike-hover.svg);
    }
    &:active{
      background-image: url(../images/vector/dislike-active.svg);
    }
    &.active{
      background-image: url(../images/vector/dislike-active.svg);
    }
  }

  &.icon-info {
    width: 48px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/info.svg);
    background-position: center center;
    background-repeat: no-repeat;

    @media (max-width: 630px) {
      width: 66px;
    }
  }
}

.swiper-button-next,
.slick-next {
  background-image: url(../images/slider-arrow-next.png);
  width: 50px;
  height: 50px;
  -webkit-background-size: contain;
  background-size: contain;
  font-size: 0;
  @media (max-width: 560px) {
    transform: scale(0.8);
  }
}

.swiper-button-prev,
.slick-prev {
  background-image: url(../images/slider-arrow-prev.png);
  width: 50px;
  height: 50px;
  font-size: 0;
  -webkit-background-size: contain;
  background-size: contain;
  @media (max-width: 560px) {
    transform: scale(0.8);
  }
}

.header-block {
  width: 100%;
  text-align: center;
  color: #292929;
  font: {
    family: $ossem;
    size: rem(32px);
    weight: 600;
  }
  line-height: 44px;
  position: relative;

  //&:after {
  //  position: absolute;
  //  content: '';
  //  width: 64px;
  //  height: 6px;
  //  background-color: #ffc519;
  //  bottom: -32px;
  //  left: 50%;
  //  transform: translateX(-50%);
  //}
}

.green-link {
  color: $green;
  font: {
    family: $baseFonts;
    size: rem(13px);
    weight: $font-weight--bold;
  }
  line-height: 21.01px;
  text-transform: uppercase;
  @include transition();

  &:hover {
    text-decoration: none;
  }
}

.errorlist {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  font: {
    family: $baseFonts;
    size: rem(14px);
    weight: $font-weight--regular;
  }
  color: #ff0000;

  & + input,
  & + textarea {
    border: 1px solid #ff0000 !important;
  }
}

input:not([type="checkbox"]),
input:not([type="radio"]) {
  position: relative;

  &:before {
    position: absolute;
    content: attr(data-placeholder);
    display: block;
    left: 20px;
    font: {
      family: $baseFonts;
      size: rem(14px);
    }
    color: lighten($base-color, 10%);
    width: 100%;
  }
}

.mfp-arrow {
  opacity: 0.8 !important;
  @include transition();
  @media (max-width: 560px) {
    transform: scale(0.8);
  }

  &:after,
  &:before {
    display: none !important;
    background-color: #fff;
    opacity: 1;
  }

  &.mfp-arrow-left {
    background-image: url(../images/slider-arrow-prev.png);
    background-color: $green;
    width: 50px;
    height: 50px;
    font-size: 0;
    left: 10px;
    -webkit-background-size: contain;
    background-size: contain;
  }

  &.mfp-arrow-right {
    right: 10px;
    background-image: url(../images/slider-arrow-next.png);
    background-color: $green;
    width: 50px;
    height: 50px;
    font-size: 0;
    -webkit-background-size: contain;
    background-size: contain;
    //@media (max-width: 560px){
    //  transform: scale(.8);
    //}
  }
}

.mfp-counter {
  display: none;
}

.rules {
  display: block;
  font-size: 12px;
  color: #acacac;
  padding-left: 45px;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;

    &:checked + .accept {
      display: block;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    display: block;
    background-color: $green;
    border-radius: 4px;
  }

  .accept {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 12px;
    height: 10px;
    display: none;
    background-image: url("../images/vector/check.svg");
  }
}

input,
textarea {
  border-radius: 6px;
}

.alert {
  padding: 10px 0;
  background-color: #f8f8f8;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .alert-text {
    flex: 1 1 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1.5;

    @media (max-width: 1280px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .alert-close {
    width: 27px;
    height: 27px;
    border: none;
    background-color: initial;

    .close-popup {
      @include sprite($close);
    }
  }
}

.scroll-up {
  padding: 8px 32px;
  width: 100%;
  max-width: 216px;
  background-color: $link-color--hover;
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  color: $white;
  font-size: rem(12);
  font-weight: $font-weight--bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;

  @media (min-width: 768px) {
    display: none !important;
  }

  &:not(.show) {
    display: none;
  }

  &.show {
    display: flex;
  }

  svg {
    margin-left: 4px;
  }
}

a {
  cursor: pointer;
  @include transition();
}

button {
  cursor: pointer;
  @include transition();
}

.mark-disc{
  list-style: disc;
}
.mark-decimal{
  list-style: decimal;
}
.quote{
  &:after{
    content: close-quote;
  }
  &:before{
    content: open-quote;
  }
}