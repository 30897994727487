@media print {

  @page {
    margin: $print-page-margin;
  }

  body {
    background-color: #fff;
  }
}
