@charset "UTF-8";
/*------------------------------------*\
  # Path
\*------------------------------------*/
/*------------------------------------*\
    # Colors
    # http://chir.ag/projects/name-that-color
\*------------------------------------*/
/*------------------------------------*\
    # Basic typography
\*------------------------------------*/
/*------------------------------------*\
    # Wrapper
\*------------------------------------*/
/*------------------------------------*\
  # Print
\*------------------------------------*/
/*------------------------------------*\
  # Magnific-Popup settings
\*------------------------------------*/
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------------------------------------------*\
  # Hides an element visually while still allowing the content to be accessible,
    to assistive technology, e.g. screen readers. Passing `unhide` will reverse
    the affects of the hiding, which is handy for showing the element on focus,
    for example.
  # https://goo.gl/Vf1TGn
\*------------------------------------------------------------------------*/
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
/*------------------------------------*\
  # Placeholder
\*------------------------------------*/
@import url(../../../node_modules/swiper/dist/css/swiper.min.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "Montserrat", sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

* {
  box-sizing: border-box; }

html {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media (max-width: 1200px) {
    html {
      font-size: 14px; } }

html,
body {
  height: 100%;
  position: relative; }

body {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: normal; }

a {
  color: #1C4A9F;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  -webkit-text-decoration-skip: objects; }
  a[href^="tel"] {
    color: inherit;
    text-decoration: none; }
    a[href^="tel"]:hover {
      text-decoration: underline; }
  a:hover {
    color: #2054b5;
    text-decoration: underline; }
  a:active {
    color: #143674; }

ol,
ul {
  list-style: none; }

b,
strong {
  font-weight: 600; }

i,
em {
  font-style: italic; }

s,
strike {
  text-decoration: line-through; }

dfn {
  font-style: italic; }

hr {
  margin: 10px 0;
  padding: 0;
  height: 0;
  border-color: #b7b7b7;
  border-style: solid;
  border-width: 1px 0 0 0;
  box-sizing: content-box; }

blockquote,
q {
  margin: 0;
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none; }

sub,
sup {
  font-size: 80%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

mark {
  color: #000;
  background: #ff0; }

svg:not(:root) {
  overflow: hidden; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  height: 0;
  display: none; }

img {
  border: 0; }

figure {
  margin: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

input,
textarea,
button,
select,
optgroup {
  margin: 0;
  padding: 0;
  outline: none;
  color: #292929;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: normal;
  border-radius: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

legend {
  border: 0;
  padding: 0; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  transition: all 0.2s ease-in-out 0s; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  box-sizing: content-box;
  outline-offset: -2px;
  -webkit-appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* montserrat-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff") format("woff"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf") format("truetype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2") format("woff2"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff") format("woff"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf") format("truetype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-500 - latin_cyrillic */
/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v24-latin_cyrillic-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v24-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v24-latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/montserrat-v24-latin_cyrillic-regular.woff") format("woff"), url("../fonts/montserrat-v24-latin_cyrillic-regular.ttf") format("truetype"), url("../fonts/montserrat-v24-latin_cyrillic-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v24-latin_cyrillic-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v24-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v24-latin_cyrillic-500.woff2") format("woff2"), url("../fonts/montserrat-v24-latin_cyrillic-500.woff") format("woff"), url("../fonts/montserrat-v24-latin_cyrillic-500.ttf") format("truetype"), url("../fonts/montserrat-v24-latin_cyrillic-500.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2") format("woff2"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.woff") format("woff"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf") format("truetype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot");
  /* IE9 Compat Modes */
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2") format("woff2"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff") format("woff"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf") format("truetype"), url("../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

body {
  background-color: #f8f8f8;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal; }
  body.overflow {
    overflow: hidden; }

.only-desktop {
  display: block; }
  @media (max-width: 550px) {
    .only-desktop {
      display: none; } }

.only-mobile {
  display: none; }
  @media (max-width: 550px) {
    .only-mobile {
      display: block; } }

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: auto; }
  .container > .content {
    flex: 1 0 auto;
    position: relative;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto; }

.wrapper {
  margin: 0 auto;
  padding: 0 20px;
  min-width: 280px;
  max-width: 1920px; }

[hidden] {
  display: none !important; }

.counters {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
  border: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  justify-content: center;
  align-items: center;
  display: none; }
  .preloader.active {
    display: flex; }
  .preloader span {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/circles-loader.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    animation-name: preloader;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }

@keyframes preloader {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(359deg); } }

span.icon {
  display: inline-block; }
  span.icon.icon-map {
    background-image: url(../images/sprite.png);
    background-position: -252px -19px;
    width: 14px;
    height: 19px; }
  span.icon.icon-mail {
    background-image: url(../images/sprite.png);
    background-position: -252px -42px;
    width: 18px;
    height: 14px;
    margin-right: 10px; }
  span.icon.icon-basket {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Ccircle cx='8.19902' cy='20.9285' r='1' fill='%2331302F' stroke='%2331302F' stroke-width='1.54306'/%3E%3Ccircle cx='15.9473' cy='20.9285' r='1' fill='%2331302F' stroke='%2331302F' stroke-width='1.54306'/%3E%3Cpath d='M2.00004 6.20181L4.10921 6.20181C4.59194 6.20181 5.00576 6.54665 5.09281 7.02147L5.56053 9.57254M5.56053 9.57254L6.83083 16.5011C6.91789 16.9759 7.33171 17.3208 7.81444 17.3208L17.2417 17.3208C17.7132 17.3208 18.1207 16.9914 18.2195 16.5303L19.4513 10.7821C19.5847 10.1596 19.1101 9.57254 18.4735 9.57254L5.56053 9.57254Z' stroke='%2331302F' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E"); }
  span.icon.icon-personal {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Ccircle cx='12' cy='9' r='4' stroke='%2331302F' stroke-width='2'/%3E%3Cpath d='M4.0001 20.0691C8.38026 15.6889 15.4819 15.6889 19.8621 20.0691' stroke='%2331302F' stroke-width='2' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E"); }
  span.icon.icon-pipette {
    background-image: url(../images/sprite.png);
    background-position: -165px -179px;
    width: 19px;
    height: 18px; }
  span.icon.icon-basket-mini {
    background-image: url(../images/sprite.png);
    background-position: -204px -144px;
    width: 40px;
    height: 40px; }
  span.icon.icon-vk {
    background-image: url(../images/vector/vk.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-vk-black {
      background-image: url(../images/vector/vk-black.svg); }
      span.icon.icon-vk-black:hover {
        background-image: url(../images/vector/vk-hover.svg); }
    span.icon.icon-vk:hover {
      background-image: url(../images/vector/vk-hover.svg); }
  span.icon.icon-fb {
    background-image: url(../images/vector/fb.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-fb-black {
      background-image: url(../images/vector/fb-black.svg); }
      span.icon.icon-fb-black:hover {
        background-image: url(../images/vector/fb-hover.svg); }
    span.icon.icon-fb:hover {
      background-image: url(../images/vector/fb-hover.svg); }
  span.icon.icon-whatsapp {
    background-image: url(../images/vector/whatsapp-white.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-whatsapp-black {
      background-image: url(../images/vector/whatsapp-black.svg); }
      span.icon.icon-whatsapp-black:hover {
        background-image: url(../images/vector/whatsapp-white-hover.svg); }
    span.icon.icon-whatsapp:hover {
      background-image: url(../images/vector/whatsapp-white-hover.svg); }
  span.icon.icon-inst {
    background-image: url(../images/vector/inst.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-inst:hover {
      background-image: url(../images/vector/inst-hover.svg); }
  span.icon.icon-pin {
    background-image: url(../images/vector/pin.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-pin-black {
      background-image: url(../images/vector/pin-black.svg); }
      span.icon.icon-pin-black:hover {
        background-image: url(../images/vector/pin-hover.svg); }
    span.icon.icon-pin:hover {
      background-image: url(../images/vector/pin-hover.svg); }
  span.icon.icon-dzen {
    background-image: url(../images/vector/dzen.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-dzen-black {
      background-image: url(../images/vector/dzen-black.svg); }
      span.icon.icon-dzen-black:hover {
        background-image: url(../images/vector/dzen.svg); }
    span.icon.icon-dzen:hover {
      background-image: url(../images/vector/dzen-black.svg); }
  span.icon.icon-youtube {
    background-image: url(../images/vector/youtube.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-youtube-black {
      background-image: url(../images/vector/youtube-black.svg); }
      span.icon.icon-youtube-black:hover {
        background-image: url(../images/vector/youtube-hover.svg); }
    span.icon.icon-youtube:hover {
      background-image: url(../images/vector/youtube-hover.svg); }
  span.icon.icon-google {
    background-image: url(../images/sprite.png);
    background-position: -149px 0px;
    width: 51px;
    height: 50px; }
  span.icon.icon-vk-color {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/vector/vk-icon.svg);
    background-position: center center;
    background-repeat: no-repeat; }
  span.icon.icon-fb-color {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/fb-black.svg);
    background-position: center center;
    background-repeat: no-repeat; }
  span.icon.icon-tg {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/vector/tg.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%; }
    span.icon.icon-tg-black {
      background-image: url(../images/vector/tg-black.svg); }
      span.icon.icon-tg-black:hover {
        background-image: url(../images/vector/tg-hover.svg); }
    span.icon.icon-tg:hover {
      background-image: url(../images/vector/tg-hover.svg); }
  span.icon.icon-wa {
    width: 40px;
    height: 40px;
    display: block;
    background-image: url(../images/vector/wa-icon.svg);
    background-position: center center;
    background-repeat: no-repeat; }
  span.icon.icon-inst-color {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/inst-black.svg);
    background-position: center center;
    background-repeat: no-repeat; }
  span.icon.icon-whatsapp-color {
    width: 24px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/whatsapp-black.svg);
    background-position: center center;
    background-repeat: no-repeat; }
  span.icon.icon-google-color {
    background-image: url(../images/sprite.png);
    background-position: -55px -94px;
    width: 51px;
    height: 50px; }
  span.icon.icon-dilivery {
    background-image: url(../images/sprite.png);
    background-position: -110px -94px;
    width: 34px;
    height: 23px; }
  span.icon.icon-like {
    width: 30px;
    height: 30px;
    display: block;
    background-image: url(../images/vector/like-new.svg);
    background-position: center center;
    background-repeat: no-repeat; }
    span.icon.icon-like:hover {
      background-image: url(../images/vector/like-new-active.svg); }
    span.icon.icon-like:active {
      background-image: url(../images/vector/like-new-active.svg); }
    span.icon.icon-like.active {
      background-image: url(../images/vector/like-new-active.svg); }
  span.icon.icon-dislike {
    width: 25px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/dislike.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out 0s; }
    span.icon.icon-dislike:hover {
      background-image: url(../images/vector/dislike-hover.svg); }
    span.icon.icon-dislike:active {
      background-image: url(../images/vector/dislike-active.svg); }
    span.icon.icon-dislike.active {
      background-image: url(../images/vector/dislike-active.svg); }
  span.icon.icon-info {
    width: 48px;
    height: 24px;
    display: block;
    background-image: url(../images/vector/info.svg);
    background-position: center center;
    background-repeat: no-repeat; }
    @media (max-width: 630px) {
      span.icon.icon-info {
        width: 66px; } }

.swiper-button-next,
.slick-next {
  background-image: url(../images/slider-arrow-next.png);
  width: 50px;
  height: 50px;
  -webkit-background-size: contain;
  background-size: contain;
  font-size: 0; }
  @media (max-width: 560px) {
    .swiper-button-next,
    .slick-next {
      transform: scale(0.8); } }

.swiper-button-prev,
.slick-prev {
  background-image: url(../images/slider-arrow-prev.png);
  width: 50px;
  height: 50px;
  font-size: 0;
  -webkit-background-size: contain;
  background-size: contain; }
  @media (max-width: 560px) {
    .swiper-button-prev,
    .slick-prev {
      transform: scale(0.8); } }

.header-block {
  width: 100%;
  text-align: center;
  color: #292929;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 44px;
  position: relative; }

.green-link {
  color: #1C4A9F;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 21.01px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s; }
  .green-link:hover {
    text-decoration: none; }

.errorlist {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: #ff0000; }
  .errorlist + input,
  .errorlist + textarea {
    border: 1px solid #ff0000 !important; }

input:not([type="checkbox"]),
input:not([type="radio"]) {
  position: relative; }
  input:not([type="checkbox"]):before,
  input:not([type="radio"]):before {
    position: absolute;
    content: attr(data-placeholder);
    display: block;
    left: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.875rem;
    color: #434343;
    width: 100%; }

.mfp-arrow {
  opacity: 0.8 !important;
  transition: all 0.2s ease-in-out 0s; }
  @media (max-width: 560px) {
    .mfp-arrow {
      transform: scale(0.8); } }
  .mfp-arrow:after, .mfp-arrow:before {
    display: none !important;
    background-color: #fff;
    opacity: 1; }
  .mfp-arrow.mfp-arrow-left {
    background-image: url(../images/slider-arrow-prev.png);
    background-color: #1C4A9F;
    width: 50px;
    height: 50px;
    font-size: 0;
    left: 10px;
    -webkit-background-size: contain;
    background-size: contain; }
  .mfp-arrow.mfp-arrow-right {
    right: 10px;
    background-image: url(../images/slider-arrow-next.png);
    background-color: #1C4A9F;
    width: 50px;
    height: 50px;
    font-size: 0;
    -webkit-background-size: contain;
    background-size: contain; }

.mfp-counter {
  display: none; }

.rules {
  display: block;
  font-size: 12px;
  color: #acacac;
  padding-left: 45px;
  position: relative;
  cursor: pointer; }
  .rules input {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1; }
    .rules input:checked + .accept {
      display: block; }
  .rules::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    display: block;
    background-color: #1C4A9F;
    border-radius: 4px; }
  .rules .accept {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 12px;
    height: 10px;
    display: none;
    background-image: url("../images/vector/check.svg"); }

input,
textarea {
  border-radius: 6px; }

.alert {
  padding: 10px 0;
  background-color: #f8f8f8; }
  .alert .wrapper {
    display: flex;
    align-items: center; }
  .alert .alert-text {
    flex: 1 1 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1.5; }
    @media (max-width: 1280px) {
      .alert .alert-text {
        font-size: 14px;
        line-height: 20px; } }
  .alert .alert-close {
    width: 27px;
    height: 27px;
    border: none;
    background-color: initial; }
    .alert .alert-close .close-popup {
      background-image: url(../images/sprite.png);
      background-position: -165px -148px;
      width: 27px;
      height: 27px; }

.scroll-up {
  padding: 8px 32px;
  width: 100%;
  max-width: 216px;
  background-color: #2054b5;
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90; }
  @media (min-width: 768px) {
    .scroll-up {
      display: none !important; } }
  .scroll-up:not(.show) {
    display: none; }
  .scroll-up.show {
    display: flex; }
  .scroll-up svg {
    margin-left: 4px; }

a {
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s; }

button {
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s; }

.mark-disc {
  list-style: disc; }

.mark-decimal {
  list-style: decimal; }

.quote:after {
  content: close-quote; }

.quote:before {
  content: open-quote; }

.cookie-policy {
  padding: 42px 0 71px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: #404040;
  width: 100%;
  border-top: 1px solid #4a4a4a;
  display: none; }
  @media (max-width: 1200px) {
    .cookie-policy {
      padding: 20px 0; } }
  .cookie-policy .wrapper {
    max-width: 1220px; }
  .cookie-policy-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 650px) {
      .cookie-policy-row {
        flex-wrap: wrap; } }
  .cookie-policy-text {
    margin-right: 20px;
    padding-top: 3px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3;
    color: #ffffff;
    max-width: 791px;
    letter-spacing: 0.008em; }
    @media (max-width: 650px) {
      .cookie-policy-text {
        margin-right: 0;
        padding-top: 0; } }
    .cookie-policy-text a {
      color: #4379de; }
    @media (max-width: 650px) {
      .cookie-policy-text {
        width: 100%; } }
  .cookie-policy-button {
    flex-grow: 0;
    flex-basis: 167px; }
    @media (max-width: 650px) {
      .cookie-policy-button {
        margin-top: 15px; } }
    .cookie-policy-button button {
      padding: 10px 16px;
      display: block;
      width: 100%;
      line-height: 1;
      background-color: #4379de;
      border: 0;
      border-radius: 8px;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 650px) {
        .cookie-policy-button button {
          padding-top: 8px;
          padding-bottom: 8px; } }
      .cookie-policy-button button span {
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: 0.008em;
        text-transform: uppercase; }
      .cookie-policy-button button:hover {
        background-color: #2054b5; }
      .cookie-policy-button button:active {
        background-color: #143674; }
  @media print {
    .cookie-policy {
      display: none !important; } }

.header {
  width: 100%;
  height: auto; }
  @media (max-width: 425px) {
    .header .wrapper {
      padding: 0 15px; } }
  .header-top {
    padding: 9px 0;
    background-color: #404040; }
    @media (max-width: 1095px) {
      .header-top {
        position: absolute;
        display: none;
        z-index: 9;
        max-width: 640px;
        width: 100%;
        height: 100px;
        top: 0;
        left: 0; } }
    .header-top .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%; }
      @media (max-width: 1095px) {
        .header-top .wrapper {
          align-items: flex-end; } }
    .header-top-col {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center; }
      @media (max-width: 1095px) {
        .header-top-col {
          width: 100%;
          height: 50px; } }
      .header-top-col.left {
        justify-content: flex-start; }
        @media (max-width: 1095px) {
          .header-top-col.left {
            justify-content: space-between; } }
        .header-top-col.left > div.city {
          margin-right: 50px; }
          @media (max-width: 1095px) {
            .header-top-col.left > div.city {
              margin-right: 0; } }
          .header-top-col.left > div.city p {
            display: flex;
            align-items: center;
            color: #ffffff;
            letter-spacing: 1.4px;
            font-size: 0.875rem;
            font-weight: 400; }
            .header-top-col.left > div.city p .icon {
              margin-right: 16px; }
            .header-top-col.left > div.city p a {
              margin-left: 5px;
              letter-spacing: 1.4px;
              color: #ffffff;
              text-transform: uppercase;
              transition: all 0.2s ease-in-out 0s;
              font-weight: 600;
              font-size: 0.875rem;
              border-bottom: 1px dashed #fff; }
              .header-top-col.left > div.city p a:hover {
                text-decoration: none;
                opacity: 0.8; }
      .header-top-col.right {
        justify-content: flex-end; }
        @media (max-width: 1095px) {
          .header-top-col.right {
            display: none; } }
        .header-top-col.right > div {
          display: flex;
          align-items: center; }
          .header-top-col.right > div.phone {
            margin-right: 20px; }
            .header-top-col.right > div.phone .phone_link {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              height: 1rem; }
              .header-top-col.right > div.phone .phone_link:hover {
                opacity: 0.8; }
              .header-top-col.right > div.phone .phone_link span {
                height: 100%;
                text-align: center;
                transition: all 0.2s ease-in-out 0s; }
            .header-top-col.right > div.phone p {
              margin-right: 20px; }
              .header-top-col.right > div.phone p a {
                color: #fff;
                font-size: 0.8125rem;
                font-weight: 400;
                text-transform: uppercase;
                transition: all 0.2s ease-in-out 0s; }
                .header-top-col.right > div.phone p a:hover {
                  text-decoration: none;
                  opacity: 0.8; }
            .header-top-col.right > div.phone .header-btn span {
              color: #fff;
              font-size: 0.8125rem;
              font-weight: 400;
              text-transform: uppercase;
              transition: all 0.2s ease-in-out 0s; }
            .header-top-col.right > div.phone .header-btn:hover {
              text-decoration: none; }
              .header-top-col.right > div.phone .header-btn:hover span {
                opacity: 0.8; }
          .header-top-col.right > div.link a {
            display: inline-block;
            color: #ffffff;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 32px;
            text-transform: uppercase; }
            .header-top-col.right > div.link a:hover {
              text-decoration: none;
              opacity: 0.8; }

.header-bottom {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  @media (min-width: 1096px) {
    .header-bottom {
      display: flex;
      align-items: center; } }
  @media (max-width: 1095px) {
    .header-bottom {
      height: auto;
      padding: 17px 0;
      background-color: #ffffff; } }
  @media (max-width: 425px) {
    .header-bottom {
      height: auto;
      padding: 14px 0;
      background-color: #ffffff; } }
  .header-bottom .wrapper {
    width: 100%; }
  .header-bottom .header-wrapper {
    background-color: #ffffff;
    transition: all 0.2s ease-in-out 0s;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .header-bottom .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media (max-width: 1095px) {
      .header-bottom .wrapper {
        flex-wrap: wrap;
        align-items: center; } }
    @media (max-width: 425px) {
      .header-bottom .wrapper {
        padding: 0 15px !important; } }
  .header-bottom .logo {
    width: 98px;
    display: flex;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
    right: auto; }
    @media (max-width: 1095px) {
      .header-bottom .logo {
        width: 82px; } }
    .header-bottom .logo .header-logo-white {
      display: none; }
    .header-bottom .logo .header-logo-dark {
      display: flex; }
    .header-bottom .logo a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .header-bottom .logo a img {
        width: 100%; }
  .header-bottom .personal-link {
    display: flex;
    align-items: center;
    margin: 0;
    padding-right: 16px; }
    @media (max-width: 1095px) {
      .header-bottom .personal-link {
        display: none !important; } }
    .header-bottom .personal-link .personal-link {
      margin-right: 35px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 600px) {
        .header-bottom .personal-link .personal-link {
          margin-right: 10px; } }
      @media (max-width: 375px) {
        .header-bottom .personal-link .personal-link {
          margin-right: 5px; } }
      .header-bottom .personal-link .personal-link span {
        display: inline-block;
        vertical-align: middle; }
        .header-bottom .personal-link .personal-link span.icon {
          position: relative;
          display: block;
          flex-shrink: 0; }
        .header-bottom .personal-link .personal-link span .coutn-personal {
          position: absolute;
          width: 5px;
          height: 5px;
          display: block;
          background-color: #1C4A9F;
          border-radius: 50%;
          top: 0;
          right: 0; }
        .header-bottom .personal-link .personal-link span.title {
          color: #333a40;
          transition: all 0.2s ease-in-out 0s;
          font-size: 0.8125rem;
          font-weight: 600;
          margin-left: 14px;
          text-transform: uppercase;
          flex-shrink: 0; }
          @media (max-width: 1240px) {
            .header-bottom .personal-link .personal-link span.title {
              display: none; } }
      .header-bottom .personal-link .personal-link:hover {
        text-decoration: none; }
        .header-bottom .personal-link .personal-link:hover .title {
          opacity: 0.8; }
    .header-bottom .personal-link .basket-link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      @media (max-width: 1095px) {
        .header-bottom .personal-link .basket-link {
          margin-right: 0px; } }
      .header-bottom .personal-link .basket-link:hover {
        text-decoration: none; }
        .header-bottom .personal-link .basket-link:hover .title {
          opacity: 0.8; }
      .header-bottom .personal-link .basket-link .icon {
        width: 32px;
        height: 32px;
        background-position: center center;
        background-repeat: no-repeat; }
      .header-bottom .personal-link .basket-link .title {
        color: #333a40;
        order: 3;
        font-size: 0.8125rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 14px;
        flex-shrink: 0; }
        @media (max-width: 1240px) {
          .header-bottom .personal-link .basket-link .title {
            display: none; } }
      .header-bottom .personal-link .basket-link .coutn-basket {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        /* identical to box height, or 16px */
        text-align: center;
        color: #404040;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        left: 30px;
        top: 50%;
        transform: translateY(-50%); }
  .header-bottom .socials {
    display: none; }
    @media (max-width: 1095px) {
      .header-bottom .socials {
        display: flex; } }
    .header-bottom .socials__link {
      display: block;
      width: 24px;
      height: 24px;
      margin-left: 16px; }
      @media (max-width: 768px) {
        .header-bottom .socials__link {
          margin-left: 8px; } }
      .header-bottom .socials__link svg {
        width: 100%;
        height: 100%; }

/*.fixed {
  position: fixed;
  max-width: 1920px;
  margin: 0 auto auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  background-color: $body-bg-color;
  width: 100%;
  box-shadow: 2px 2px 2px $gray;
  visibility: hidden;
  //@include transition();
  height: 80px;
  @media (max-width: 1095px) {
    display: none;
  }

  &-logo {
    height: 32px;
    width: auto;
    display: block;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    @media (max-width: 1095px) {
      width: 100%;
      order: 2;
      position: relative;
      top: auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: auto;
        height: 32px;
        display: block;
      }
    }
  }

  &-personal-link {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: {
      right: 0;
      left: auto;
    };
    @media (max-width: 1095px) {
      height: 30px;
    }

    .personal-link {
      margin-right: 10px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: inline-block;
        vertical-align: middle;

        &.icon {
          position: relative;
          display: block;
          flex-shrink: 0;
        }

        .coutn-personal {
          position: absolute;
          width: 5px;
          height: 5px;
          display: block;
          background-color: $green;
          border-radius: 50%;
          top: 0;
          right: 0;
        }

        &.title {
          color: #333a40;
          font: {
            size: rem(13px);
            weight: $font-weight--semibold;
          }
          margin-right: 14px;
          text-transform: uppercase;
          flex-shrink: 0;
          @media (max-width: 1240px) {
            display: none;
          }
        }
      }

      &:hover {
        text-decoration: none;

        .title {
          color: $link-color;
        }
      }
    }

    .basket-link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 24px;
      @media (max-width: 1095px) {
        margin-right: 0px;
      }

      &:hover {
        text-decoration: none;

        .title {
          color: $link-color;
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .title {
        color: #333a40;
        font: {
          size: rem(13px);
          weight: $font-weight--semibold;
        }
        margin-right: 14px;
        text-transform: uppercase;
        flex-shrink: 0;
        @media (max-width: 1240px) {
          display: none;
        }
      }

      .coutn-basket {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        !* identical to box height, or 16px *!

        text-align: center;
        color: #404040;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        left: calc(100% + 4px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-menu {
    width: 50%;

    .menu-btn {
      display: none;
      @media (max-width: 1095px) {
        display: block;
        z-index: 9999;
        position: relative;
      }

      &:hover {
        text-decoration: none;
      }

      &.active {
        span {
          &.burger {
            transform: rotate(90deg);
          }
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;

        &.burger {
          margin-right: 10px;
          position: relative;
          width: 20px;
          height: 2px;
          background-color: #A1E7D8;
          @include transition();

          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: #A1E7D8;
            transform: translateY(5px);
            bottom: 0;
            left: 0;
            z-index: 1;
          }

          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: #A1E7D8;
            transform: translateY(-5px);
            bottom: 0;
            left: 0;
            z-index: 1;
          }

        }

        &.info {
          font: {
            size: rem(16px);
            weight: $font-weight--bold;
          }
          color: $base-color;
          text-transform: uppercase;
        }
      }
    }

    & > ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 1095px) {
        display: none;
        position: absolute;
        top: 63px;
        left: 0;
        right: 0;
        max-width: 640px;
        z-index: 999;
        background-color: #fff;
        box-shadow: 1px 1px 1px rgba(41, 41, 41, .5);
        box-sizing: border-box;
        padding: 40px 20px 20px;

      }

      & > li {
        padding-left: 30px;
        box-sizing: border-box;
        margin-right: rem(40px);
        @media (min-width: 991px) {
          &:first-child {
            padding-left: 0;
          }
        }
        @media(max-width: 1095px) {
          margin: {
            right: 0px !important;
            left: 0px !important;
            bottom: 10px !important;
            top: 0px !important;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 1600px) {
          padding-left: 0;

        }
        @media (max-width: 1420px) {
          margin-right: 20px;
        }
        @media (max-width: 1290px) {
          &:first-child {
            padding-left: 0;
          }
        }

        & > span.show-dropdown {
          display: none;
          @include transition();
          @media (max-width: 1095px) {
            display: block;
            position: absolute;
            cursor: pointer;
            z-index: 40;
            right: 10px;
            margin-top: -34px;
            width: 40px;
            height: 40px;
            &:after {
              content: '';
              position: absolute;
              width: 14px;
              height: 14px;
              border: {
                top: 2px solid $green;
                right: 2px solid $green;
              };
              transform: rotate(45deg);
              top: 50%;
              left: 50%;
              margin-left: -7px;
              margin-top: -7px;
              @include transition();
            }

            &.active {
              transform: rotate(90deg);
            }
          }
        }

        & > a {
          color: #333a40;
          font: {
            size: rem(13px);
            weight: $font-weight--bold;
          }
          line-height: 21px;
          text-transform: uppercase;
          position: relative;


          &:hover {
            text-decoration: none;
            color: $link-color;

          }

        }

        &.dropdown {
          position: relative;

          &:hover {
            .submenu {
              //display: block;
              @media(max-width: 1095px) {
                display: none;
              }
            }
          }

          .submenu {
            display: none;
            position: absolute;
            width: 251px;
            box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
            background-color: #ffffff;
            box-sizing: border-box;
            padding: 10px 0;
            z-index: 99;
            @media (max-width: 1095px) {
              position: relative;
              width: 100%;
              box-shadow: none;
              padding: 0;
            }

            & > ul {
              width: 100%;

              & > li {
                min-height: 48px;
                @media (max-width: 1095px) {
                  min-height: 24px;
                }

                a {
                  padding: 14px 0;
                  line-height: 20px;
                  display: block;
                  font: {
                    family: $baseFonts;
                    weight: $font-weight--regular;
                    size: rem(13px);
                  }
                  color: #333a40;
                  @include transition();
                  padding: {
                    left: 30px;
                  };
                  @media (max-width: 1095px) {
                    line-height: 24px;
                    font-size: rem(18px);
                    padding-left: 5px;
                  }

                  &:hover {
                    background-color: #A1E7D8;
                    color: #fff;
                    font-weight: $font-weight--bold;
                    text-decoration: none;
                  }
                }

                !* &.sublink {
                   &:hover {
                     .subinfo {
                       display: flex;
                       flex-direction: column;
                       align-items: center;
                       justify-content: center;
                       @media(max-width: 1095px) {
                         display: none;
                       }
                     }
                   }


                  !* .subinfo {
                     display: none;
                     position: absolute;
                     border-left: 1px solid #ebebeb;
                     box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
                     box-sizing: border-box;
                     left: 100%;
                     top: 0;
                     width: 400px;
                     min-height: 100%;
                     background-color: #ffffff;

                     & > ul {
                       height: 100%;

                       & > li {
                         height: 100%;
                         display: flex;
                         flex-direction: column;
                         align-items: center;
                         padding: 0 rem(38px);

                         .img {
                           width: 130px;

                           a {
                             padding-left: 0;
                           }

                           a:hover {
                             background-color: transparent;
                             color: transparent;

                           }

                           img {
                             display: block;
                             width: 100%;
                           }
                         }

                         .title {
                           color: #8b8b8b;
                           font: {
                             family: $baseFonts;
                             size: rem(14px);
                             weight: $font-weight--regular;
                           }
                           line-height: 21px;
                           text-align: center;
                         }
                       }
                     }
                   }*!
                 }*!
              }
            }
          }
        }
      }

      & > p {
        display: none;
        @media (max-width: 1095px) {
          display: block;
          color: $gray;

          a {
            color: #A1E7D8;
            font-size: 1.3rem;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

}*/
@media (min-width: 1095px) {
  .header-transparent .header-bottom {
    position: absolute;
    top: 50px; }
    .header-transparent .header-bottom .header-wrapper {
      background: rgba(255, 255, 255, 0.06);
      transition: all 0.2s ease-in-out 0s; }
    .header-transparent .header-bottom .header-logo-white {
      display: block; }
    .header-transparent .header-bottom .header-logo-dark {
      display: none; }
    .header-transparent .header-bottom .main-menu__list a {
      color: #ffffff;
      transition: all 0.2s ease-in-out 0s; }
      .header-transparent .header-bottom .main-menu__list a:hover {
        color: rgba(255, 255, 255, 0.8); }
    .header-transparent .header-bottom .search-icon {
      background-image: url(../images/search-icon-white.svg);
      transition: all 0.2s ease-in-out 0s; }
      .header-transparent .header-bottom .search-icon:hover .search-name {
        opacity: 0.8; }
    .header-transparent .header-bottom .search-name {
      color: #ffffff;
      transition: all 0.2s ease-in-out 0s; }
    .header-transparent .header-bottom .personal-link .icon-personal {
      background-image: url(../images/icon-personal.svg);
      transition: all 0.2s ease-in-out 0s; }
    .header-transparent .header-bottom .personal-link span.title {
      color: #ffffff;
      transition: all 0.2s ease-in-out 0s; }
    .header-transparent .header-bottom .personal-link .coutn-basket {
      color: #ffffff;
      transition: all 0.2s ease-in-out 0s; }
    .header-transparent .header-bottom .personal-link .icon-basket {
      background-image: url(../images/icon-basket.svg);
      transition: all 0.2s ease-in-out 0s; } }

.menu-mobile {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 16px 48px;
  background-color: #ffffff;
  z-index: 30;
  justify-content: space-between;
  display: none; }
  @media (max-width: 1095px) {
    .menu-mobile {
      display: flex; } }
  @media (max-width: 768px) {
    .menu-mobile {
      padding: 8px 16px;
      justify-content: space-around; } }
  @media (max-width: 425px) {
    .menu-mobile {
      padding: 2px 16px; } }
  .menu-mobile__btn {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 8px;
    color: rgba(41, 41, 41, 0.7);
    font-weight: 600;
    cursor: pointer; }
    .menu-mobile__btn:hover {
      color: rgba(41, 41, 41, 0.7);
      text-decoration: none; }
    .menu-mobile__btn:active {
      color: rgba(41, 41, 41, 0.7);
      text-decoration: none; }
    @media (max-width: 768px) {
      .menu-mobile__btn {
        flex-direction: column;
        font-size: 12px; } }
    @media (max-width: 425px) {
      .menu-mobile__btn {
        font-size: 10px; } }
    .menu-mobile__btn svg {
      margin-right: 4px;
      width: 24px;
      height: 24px; }
      @media (max-width: 768px) {
        .menu-mobile__btn svg {
          margin-right: 0;
          margin-bottom: 2px; } }

.main-menu {
  width: auto;
  margin-left: 0;
  margin-right: auto; }
  @media (max-width: 1095px) {
    .main-menu {
      order: 0;
      width: 50%; } }
  @media (max-width: 600px) {
    .main-menu {
      width: 30%; } }
  .main-menu-hide {
    display: none; }
    @media (max-width: 1095px) {
      .main-menu-hide {
        display: block; } }
  .main-menu .menu-btn {
    display: none; }
    @media (max-width: 1095px) {
      .main-menu .menu-btn {
        display: block;
        z-index: 9999;
        position: relative; } }
    .main-menu .menu-btn:hover {
      text-decoration: none; }
    .main-menu .menu-btn.active span.burger {
      transform: rotate(-45deg);
      background-color: #d6d8d9; }
      .main-menu .menu-btn.active span.burger:after {
        transform: rotate(90deg);
        background-color: #d6d8d9; }
      .main-menu .menu-btn.active span.burger:before {
        background-color: #d6d8d9;
        transform: scale(0); }
    .main-menu .menu-btn span {
      display: inline-block;
      vertical-align: middle; }
      .main-menu .menu-btn span.burger {
        margin-right: 10px;
        position: relative;
        width: 21px;
        height: 2px;
        background-color: #1C4A9F;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 375px) {
          .main-menu .menu-btn span.burger {
            margin-right: 5px; } }
        .main-menu .menu-btn span.burger:after {
          position: absolute;
          content: "";
          display: block;
          width: 21px;
          height: 2px;
          background-color: #1C4A9F;
          transform: translateY(6px);
          bottom: 0;
          left: 0;
          z-index: 1; }
        .main-menu .menu-btn span.burger:before {
          position: absolute;
          content: "";
          display: block;
          width: 21px;
          height: 2px;
          background-color: #1C4A9F;
          transform: translateY(-6px);
          bottom: 0;
          left: 0;
          z-index: 1; }
      .main-menu .menu-btn span.info {
        font-size: 1rem;
        font-weight: 600;
        color: #292929;
        text-transform: uppercase; }
        @media (max-width: 425px) {
          .main-menu .menu-btn span.info {
            display: none; } }
  @media (max-width: 1095px) {
    .main-menu__content {
      display: none;
      padding: 0 0 8px;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      z-index: 999;
      width: 100vw;
      background-color: #fff;
      box-shadow: 1px 10px 10px rgba(41, 41, 41, 0.2);
      box-sizing: border-box;
      height: calc(var(--vh,1vh) * 100 - 58px);
      overflow-y: auto; } }
  @media (max-width: 425px) {
    .main-menu__content {
      top: 36px;
      height: calc(var(--vh,1vh) * 100 - 50px); } }
  .main-menu__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative; }
    @media (max-width: 1095px) {
      .main-menu__list {
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        height: 100%; } }
    @media (min-width: 1096px) {
      .main-menu__list {
        display: flex; } }
    .main-menu__list .user {
      display: none; }
      @media (max-width: 1095px) {
        .main-menu__list .user {
          display: flex;
          align-items: center;
          font-size: 16px;
          padding: 16px;
          color: #292929; } }
      .main-menu__list .user svg path {
        stroke: #434444; }
    .main-menu__list > li {
      box-sizing: border-box; }
      @media (min-width: 991px) {
        .main-menu__list > li:first-child {
          padding-left: 0; } }
      @media (max-width: 1095px) {
        .main-menu__list > li {
          width: 100%;
          margin-right: 0px;
          margin-left: 0px;
          margin-bottom: 4px;
          margin-top: 0px; } }
      .main-menu__list > li:last-child {
        margin-right: 0; }
      @media (max-width: 1600px) {
        .main-menu__list > li {
          padding-left: 0; } }
      @media (max-width: 1290px) {
        .main-menu__list > li:first-child {
          padding-left: 0; } }
      .main-menu__list > li a {
        padding: 26px 16px;
        color: #333a40;
        font-size: 0.875rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.5;
        position: relative;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 25px;
        transition: all 0.2s ease-in-out 0s; }
        .main-menu__list > li a:hover {
          text-decoration: none;
          color: rgba(41, 41, 41, 0.7); }
        @media (max-width: 1540px) {
          .main-menu__list > li a {
            padding: 24px 8px;
            font-size: 0.8125rem; } }
        @media (max-width: 1250px) {
          .main-menu__list > li a {
            font-size: 11px;
            font-weight: 400; } }
        @media (max-width: 1095px) {
          .main-menu__list > li a {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-transform: none;
            padding: 16px; } }
        .main-menu__list > li a img {
          display: none; }
          @media (max-width: 1095px) {
            .main-menu__list > li a img {
              display: block;
              width: 24px;
              height: 24px; } }
      .main-menu__list > li.dropdown {
        position: relative; }
        .main-menu__list > li.dropdown .dropdown-top {
          display: flex;
          position: relative;
          /* .show-dropdown {
             width: 100px;
             height: 100px;
             background-color: $body-bg-color;
             border-radius: 100% 0;
             position: absolute;
             top: 40px;
             left: 50%;
             transform: skew(20deg) rotate(40deg) translateX(-50%);
             display: none;
             //opacity: 0;
             // @include transition();

           }*/ }
          @media (max-width: 1095px) {
            .main-menu__list > li.dropdown .dropdown-top {
              width: 100%; }
              .main-menu__list > li.dropdown .dropdown-top.active {
                background-color: #1C4A9F;
                color: #ffffff; }
                .main-menu__list > li.dropdown .dropdown-top.active a {
                  color: #ffffff; }
                  .main-menu__list > li.dropdown .dropdown-top.active a .mobile-active {
                    display: block; }
                  .main-menu__list > li.dropdown .dropdown-top.active a .default {
                    display: none; }
                  .main-menu__list > li.dropdown .dropdown-top.active a svg path {
                    stroke: #ffffff; } }
          @media (max-width: 1095px) {
            .main-menu__list > li.dropdown .dropdown-top a {
              width: 100%;
              padding: 16px;
              font-weight: 600;
              font-size: 18px;
              line-height: 26px; } }
          .main-menu__list > li.dropdown .dropdown-top a svg {
            display: none; }
            @media (max-width: 1095px) {
              .main-menu__list > li.dropdown .dropdown-top a svg {
                display: block; } }
          .main-menu__list > li.dropdown .dropdown-top a .mobile-active {
            display: none; }
          .main-menu__list > li.dropdown .dropdown-top span.show-dropdown {
            display: none;
            transition: all 0.2s ease-in-out 0s;
            margin-left: 16px; }
            @media (max-width: 1095px) {
              .main-menu__list > li.dropdown .dropdown-top span.show-dropdown {
                position: relative;
                cursor: pointer;
                z-index: 40;
                width: 100%; }
                .main-menu__list > li.dropdown .dropdown-top span.show-dropdown::before, .main-menu__list > li.dropdown .dropdown-top span.show-dropdown::after {
                  transition: all 0.2s ease-in-out 0s;
                  content: "";
                  position: absolute;
                  width: 10px;
                  height: 2px;
                  background: #333a40; }
                .main-menu__list > li.dropdown .dropdown-top span.show-dropdown::before {
                  top: 16px;
                  right: 0;
                  transform-origin: 0 50%;
                  transform: rotate(135deg); }
                .main-menu__list > li.dropdown .dropdown-top span.show-dropdown::after {
                  top: 17px;
                  right: 10px;
                  transform-origin: 100% 50%;
                  transform: rotate(45deg); }
                .main-menu__list > li.dropdown .dropdown-top span.show-dropdown.active::before, .main-menu__list > li.dropdown .dropdown-top span.show-dropdown.active::after {
                  width: 20px; }
                .main-menu__list > li.dropdown .dropdown-top span.show-dropdown.active::before {
                  top: 4px;
                  right: -9px; }
                .main-menu__list > li.dropdown .dropdown-top span.show-dropdown.active::after {
                  top: 18px; } }
        .main-menu__list > li.dropdown:hover {
          /*
           .show-dropdown {
             opacity: 1;
           }*/ }
          .main-menu__list > li.dropdown:hover .submenu {
            display: flex; }
            @media (max-width: 1095px) {
              .main-menu__list > li.dropdown:hover .submenu {
                display: none; } }
        .main-menu__list > li.dropdown .submenu {
          display: none;
          position: absolute;
          width: 558px;
          left: 0;
          top: 100%;
          background-color: #f8f8f8;
          box-sizing: border-box;
          z-index: 99;
          max-height: 350px;
          /*&__models {
            overflow-x: hidden;
            overflow-y: auto;
            @include scrollbar;
            width: 308px;
            padding: 0 16px;
            background: $white;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            max-height: 100%;
            display: none;
            @media (max-width: 1095px) {
              width: 100%;
              position: static;
              max-height: none;
              background: #F8F8F8;
              padding: 0;

            }
          }*/ }
          @media (max-width: 1095px) {
            .main-menu__list > li.dropdown .submenu {
              position: absolute;
              width: calc(100% - 56px);
              right: 0;
              left: auto;
              box-shadow: none;
              display: none;
              background: #E9EDF6;
              max-height: none;
              top: 59px; } }
          .main-menu__list > li.dropdown .submenu.visible {
            display: flex; }
            @media (max-width: 1095px) {
              .main-menu__list > li.dropdown .submenu.visible {
                flex-direction: column; } }
          .main-menu__list > li.dropdown .submenu .categories-list {
            scrollbar-width: thin;
            scrollbar-color: #4f4f52 #eeeeee;
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            max-height: 350px; }
            .main-menu__list > li.dropdown .submenu .categories-list::-webkit-scrollbar {
              height: 2px;
              width: 2px;
              background-color: #eeeeee; }
              .main-menu__list > li.dropdown .submenu .categories-list::-webkit-scrollbar-thumb {
                background-color: #4f4f52; }
            @media (max-width: 1095px) {
              .main-menu__list > li.dropdown .submenu .categories-list {
                width: 100%;
                max-height: none; } }
          .main-menu__list > li.dropdown .submenu > ul {
            width: 250px;
            display: flex;
            flex-direction: column; }
            @media (max-width: 1095px) {
              .main-menu__list > li.dropdown .submenu > ul {
                width: 45%; } }
            .main-menu__list > li.dropdown .submenu > ul > li {
              /* min-height: 48px;
               @media (max-width: 1095px) {
                 min-height: 24px;
               }*/ }
              .main-menu__list > li.dropdown .submenu > ul > li a {
                padding: 13px 0;
                text-transform: none;
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                line-height: 1.5;
                font-weight: 600;
                text-transform: uppercase;
                color: #333a40;
                transition: all 0.2s ease-in-out 0s;
                padding-left: 16px; }
                @media (max-width: 1095px) {
                  .main-menu__list > li.dropdown .submenu > ul > li a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: none;
                    padding: 16px; } }
                .main-menu__list > li.dropdown .submenu > ul > li a span svg {
                  width: 26px;
                  height: 26px;
                  margin-right: 10px;
                  display: none; }
                  @media (max-width: 1095px) {
                    .main-menu__list > li.dropdown .submenu > ul > li a span svg {
                      display: block; } }
                .main-menu__list > li.dropdown .submenu > ul > li a:hover {
                  opacity: 0.8;
                  text-decoration: none; }
                  .main-menu__list > li.dropdown .submenu > ul > li a:hover span svg path {
                    transition: all 0.2s ease-in-out 0s;
                    fill: #ffffff; }
                @media (max-width: 1095px) {
                  .main-menu__list > li.dropdown .submenu > ul > li a:hover {
                    color: #292929; } }
              .main-menu__list > li.dropdown .submenu > ul > li.sublink {
                padding-right: 16px;
                /*
                                &:hover {
                                  .subinfo {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    @media(max-width: 1095px) {
                                      display: none;
                                    }
                                  }
                                }

                                .subinfo {
                                  display: none;
                                  position: absolute;
                                  border-left: 1px solid #ebebeb;
                                  box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
                                  box-sizing: border-box;
                                  left: 100%;
                                  top: 0;
                                  width: 400px;
                                  min-height: 100%;
                                  background-color: #ffffff;

                                  & > ul {
                                    height: 100%;

                                    & > li {
                                      height: 100%;
                                      display: flex;
                                      flex-direction: column;
                                      align-items: center;
                                      padding: 0 rem(38px);

                                      .img {
                                        width: 130px;

                                        a {
                                          padding-left: 0;
                                        }

                                        a:hover {
                                          background-color: transparent;
                                          color: transparent;

                                        }

                                        img {
                                          display: block;
                                          width: 100%;
                                        }
                                      }

                                      .title {
                                        color: #8b8b8b;
                                        font: {
                                          family: $baseFonts;
                                          size: rem(14px);
                                          weight: $font-weight--regular;
                                        }
                                        line-height: 21px;
                                        text-align: center;
                                      }
                                    }
                                  }
                                }*/ }
                @media (max-width: 1540px) {
                  .main-menu__list > li.dropdown .submenu > ul > li.sublink {
                    padding-right: 8px; } }
                @media (max-width: 1095px) {
                  .main-menu__list > li.dropdown .submenu > ul > li.sublink {
                    padding-right: 0; } }
                @media (max-width: 1095px) {
                  .main-menu__list > li.dropdown .submenu > ul > li.sublink .sublink__head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center; } }
                .main-menu__list > li.dropdown .submenu > ul > li.sublink .categories-list__item-link {
                  font-size: 0.875rem;
                  line-height: 1.5;
                  font-weight: 600;
                  text-transform: uppercase;
                  white-space: normal;
                  display: block; }
                  @media (max-width: 1540px) {
                    .main-menu__list > li.dropdown .submenu > ul > li.sublink .categories-list__item-link {
                      font-size: 0.75rem; } }
                  @media (max-width: 1095px) {
                    .main-menu__list > li.dropdown .submenu > ul > li.sublink .categories-list__item-link {
                      display: flex;
                      justify-content: space-between;
                      align-items: flex-start;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      text-transform: none;
                      padding: 16px;
                      width: 70%; } }
                .main-menu__list > li.dropdown .submenu > ul > li.sublink .categories-list__svg-link {
                  width: 20%;
                  height: 56px;
                  display: none; }
                  @media (max-width: 1095px) {
                    .main-menu__list > li.dropdown .submenu > ul > li.sublink .categories-list__svg-link {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border: none;
                      background-color: transparent; } }
                  .main-menu__list > li.dropdown .submenu > ul > li.sublink .categories-list__svg-link.active svg {
                    transform: rotate(-90deg); }
                .main-menu__list > li.dropdown .submenu > ul > li.sublink svg {
                  display: none; }
                  @media (max-width: 1095px) {
                    .main-menu__list > li.dropdown .submenu > ul > li.sublink svg {
                      display: block;
                      flex-shrink: 0;
                      width: 20px;
                      transform: rotate(90deg);
                      transition: all 0.2s ease-in-out 0s; } }
          .main-menu__list > li.dropdown .submenu .models {
            /*  &-nav {
                display: none;

                &.active {
                  display: block;
                }
              }*/ }
            .main-menu__list > li.dropdown .submenu .models-nav {
              overflow-x: hidden;
              overflow-y: auto;
              scrollbar-width: thin;
              scrollbar-color: #4f4f52 #eeeeee;
              width: 308px;
              padding: 0 16px;
              background: #ffffff;
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              max-height: 100%;
              display: none; }
              .main-menu__list > li.dropdown .submenu .models-nav::-webkit-scrollbar {
                height: 2px;
                width: 2px;
                background-color: #eeeeee; }
                .main-menu__list > li.dropdown .submenu .models-nav::-webkit-scrollbar-thumb {
                  background-color: #4f4f52; }
              @media (max-width: 1095px) {
                .main-menu__list > li.dropdown .submenu .models-nav {
                  width: 100%;
                  position: static;
                  max-height: none;
                  background: #F8F8F8;
                  padding: 0; } }
              .main-menu__list > li.dropdown .submenu .models-nav.active {
                display: flex; }
                @media (max-width: 1095px) {
                  .main-menu__list > li.dropdown .submenu .models-nav.active {
                    flex-direction: column; } }
            .main-menu__list > li.dropdown .submenu .models-list__item-link {
              padding: 13px 0 13px 16px;
              white-space: normal; }
              @media (max-width: 1095px) {
                .main-menu__list > li.dropdown .submenu .models-list__item-link {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 16px; } }
    .main-menu__list .phone {
      display: none; }
      @media (max-width: 1095px) {
        .main-menu__list .phone {
          display: block;
          color: #292929;
          font-size: 18px;
          padding: 8px 16px;
          text-decoration: underline;
          margin-top: auto; } }
    .main-menu__list .socials {
      display: none; }
      @media (max-width: 1095px) {
        .main-menu__list .socials {
          display: flex;
          padding: 16px; } }
      .main-menu__list .socials__link {
        margin: 0 16px 0 0;
        width: 40px;
        height: 40px;
        display: block; }
        @media (max-width: 660px) {
          .main-menu__list .socials__link {
            width: 32px;
            height: 32px; } }
        .main-menu__list .socials__link span {
          width: 40px;
          height: 40px;
          transition: all 0.2s ease-in-out 0s;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100%; }
          @media (max-width: 660px) {
            .main-menu__list .socials__link span {
              width: 32px;
              height: 32px; } }
  .main-menu__close {
    display: none; }
    @media (max-width: 1095px) {
      .main-menu__close {
        display: block;
        position: absolute;
        top: 4px;
        right: 4px; } }
  .main-menu__close-btn {
    position: relative;
    width: 32px;
    height: 32px;
    background: transparent;
    border: none; }
    .main-menu__close-btn::before, .main-menu__close-btn::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background: #1C4A9F; }
    .main-menu__close-btn::before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .main-menu__close-btn::after {
      transform: translate(-50%, -50%) rotate(-45deg); }

.footer {
  background: #404040;
  padding: 48px 0 49px; }
  @media (max-width: 1095px) {
    .footer {
      padding: 48px 0 72px; } }
  @media (max-width: 425px) {
    .footer {
      padding: 32px 0 59px; } }
  .footer-question {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .footer-question .title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.3;
      color: #ffffff; }
    .footer-question .text {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.3;
      text-align: center;
      color: #ffffff; }
    .footer-question .btn {
      border-radius: 8px;
      padding: 10px 16px;
      border: 1px solid #4379de;
      color: #4379de;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.3;
      text-transform: uppercase;
      letter-spacing: 0.008em;
      transition: all 0.2s ease-in-out 0s; }
      .footer-question .btn:hover {
        text-decoration: none;
        background-color: #4379de;
        color: #404040; }
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    flex-wrap: wrap;
    gap: 16px; }
    @media (max-width: 920px) {
      .footer-top {
        padding-top: 60px; } }
    @media (max-width: 660px) {
      .footer-top {
        flex-direction: column;
        padding-bottom: 44px;
        padding-top: 40px; } }
  .footer-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 48px;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #c4c4c4; }
    @media (max-width: 920px) {
      .footer-bottom {
        flex-wrap: wrap; } }
    @media (max-width: 660px) {
      .footer-bottom {
        padding-top: 20px;
        flex-direction: column;
        align-items: center; } }
    @media (max-width: 420px) {
      .footer-bottom {
        align-items: flex-start; } }
    .footer-bottom a {
      display: inline-block;
      color: #4379de;
      margin-bottom: 6px; }
    .footer-bottom p {
      margin-bottom: 11px; }
    .footer-bottom__left {
      display: flex;
      flex-direction: column;
      text-align: left; }
      @media (max-width: 660px) {
        .footer-bottom__left {
          text-align: center; } }
      @media (max-width: 420px) {
        .footer-bottom__left {
          text-align: left; } }
    .footer-bottom__right {
      text-align: right; }
      @media (max-width: 660px) {
        .footer-bottom__right {
          text-align: center; } }
      @media (max-width: 420px) {
        .footer-bottom__right {
          text-align: left; } }
  @media (max-width: 660px) {
    .footer-logo {
      margin: 0 auto 48px; } }
  .footer-menu {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 920px) {
      .footer-menu {
        width: 100%;
        order: 1;
        margin: 20px auto 0; } }
    @media (max-width: 660px) {
      .footer-menu {
        order: 0;
        margin-top: 0;
        margin-bottom: 40px;
        flex-direction: column; } }
    .footer-menu__item {
      padding: 0 10px; }
      @media (max-width: 660px) {
        .footer-menu__item {
          padding: 0;
          margin-bottom: 16px; } }
    .footer-menu__link {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 19px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.008em;
      color: #FFFFFF !important;
      transition: all 0.2s ease-in-out 0s; }
      .footer-menu__link span {
        transition: all 0.2s ease-in-out 0s; }
      .footer-menu__link:hover {
        text-decoration: none !important;
        color: #4379de !important; }
        .footer-menu__link:hover span {
          transform: translateY(-19px); }
  .footer .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px; }
    @media (max-width: 660px) {
      .footer .social-link {
        gap: 16px; } }
    .footer .social-link a:last-of-type {
      margin-right: 0; }
    .footer .social-link a .icon {
      width: 40px;
      height: 40px;
      display: block;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 660px) {
        .footer .social-link a .icon {
          width: 32px;
          height: 32px; } }

.mfp-bg {
  position: fixed !important;
  height: 100% !important; }

.mfp-container {
  padding: 0 !important; }

.mfp-wrap {
  overflow: hidden auto;
  position: fixed !important;
  height: 100% !important;
  top: 0 !important; }

.mfp-content .modal, .mfp-content .white-popup {
  max-width: 580px;
  background-color: #fff;
  width: 100%;
  padding: 60px 65px;
  box-sizing: border-box;
  position: relative;
  margin: auto; }
  @media (max-width: 1200px) {
    .mfp-content .modal, .mfp-content .white-popup {
      padding: 30px; } }
  @media (max-width: 1200px) {
    .mfp-content .modal, .mfp-content .white-popup {
      max-width: 400px; } }
  .mfp-content .modal .close-popup, .mfp-content .white-popup .close-popup {
    position: absolute;
    right: 10px;
    top: 10px;
    background-image: url(../images/sprite.png);
    background-position: -165px -148px;
    width: 27px;
    height: 27px;
    cursor: pointer; }
  .mfp-content .modal form, .mfp-content .white-popup form {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .mfp-content .modal .form-header, .mfp-content .white-popup .form-header {
    width: 100%;
    text-align: center;
    font-family: "Ossem-Rounded", Sans-Serif;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 55px; }
    @media (max-width: 1200px) {
      .mfp-content .modal .form-header, .mfp-content .white-popup .form-header {
        margin-bottom: 20px; } }
  .mfp-content .modal label, .mfp-content .white-popup label {
    display: block;
    width: 100%;
    position: relative; }
  .mfp-content .modal input[type="text"], .mfp-content .modal input[type="email"], .mfp-content .modal input[type="password"], .mfp-content .white-popup input[type="text"], .mfp-content .white-popup input[type="email"], .mfp-content .white-popup input[type="password"] {
    width: 100%;
    font-size: 1rem;
    color: #333a40;
    padding: 0 20px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #cbd7d5;
    font-family: "Source Sans Pro", Sans-Serif;
    margin-bottom: 10px; }
    @media (max-width: 420px) {
      .mfp-content .modal input[type="text"], .mfp-content .modal input[type="email"], .mfp-content .modal input[type="password"], .mfp-content .white-popup input[type="text"], .mfp-content .white-popup input[type="email"], .mfp-content .white-popup input[type="password"] {
        height: 38px;
        line-height: 30px;
        margin-bottom: 10px;
        padding: 0 10px; } }
  .mfp-content .modal textarea, .mfp-content .white-popup textarea {
    width: 100%;
    height: 150px;
    font-size: 1rem;
    color: #333a40;
    padding: 20px;
    border: 1px solid #cbd7d5;
    font-family: "Source Sans Pro", Sans-Serif;
    margin-bottom: 10px;
    resize: none; }
    @media (max-width: 420px) {
      .mfp-content .modal textarea, .mfp-content .white-popup textarea {
        height: 70px;
        padding: 5px 10px; } }
  .mfp-content .modal .rules, .mfp-content .white-popup .rules {
    font-size: 12px;
    color: #acacac;
    padding-left: 45px;
    position: relative;
    cursor: pointer; }
    .mfp-content .modal .rules input, .mfp-content .white-popup .rules input {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 1px;
      height: 1px;
      opacity: 0 !important;
      z-index: -1; }
      .mfp-content .modal .rules input:checked + .accept, .mfp-content .white-popup .rules input:checked + .accept {
        display: block; }
    .mfp-content .modal .rules::before, .mfp-content .white-popup .rules::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      display: block;
      background-color: #1C4A9F;
      border-radius: 4px; }
    .mfp-content .modal .rules .accept, .mfp-content .white-popup .rules .accept {
      position: absolute;
      top: 7px;
      left: 5px;
      width: 12px;
      height: 10px;
      display: none;
      background-image: url("../images/vector/check.svg");
      background-repeat: no-repeat; }
  .mfp-content .modal input[type="submit"], .mfp-content .white-popup input[type="submit"] {
    width: 100%;
    max-width: 340px;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    border-radius: 8px;
    background-color: #1C4A9F;
    margin-top: 40px;
    transition: all 0.2s ease-in-out 0s; }
    .mfp-content .modal input[type="submit"]:hover, .mfp-content .white-popup input[type="submit"]:hover {
      background-color: #2054b5; }
    .mfp-content .modal input[type="submit"]:active, .mfp-content .white-popup input[type="submit"]:active {
      background-color: #1C4A9F; }
  .mfp-content .modal .mfp-close, .mfp-content .white-popup .mfp-close {
    display: none; }
  .mfp-content .modal-city .form-header, .mfp-content .white-popup-city .form-header {
    margin-bottom: 25px; }
    .mfp-content .modal-city .form-header span, .mfp-content .white-popup-city .form-header span {
      color: #1C4A9F; }
    .mfp-content .modal-city .form-header p, .mfp-content .white-popup-city .form-header p {
      font-family: "Montserrat", sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      color: #b7b7b7; }
  .mfp-content .modal-city label, .mfp-content .white-popup-city label {
    position: relative;
    height: 48px; }
  .mfp-content .modal-city div.suggestions, .mfp-content .white-popup-city div.suggestions {
    position: absolute;
    top: 100%;
    background-color: #fff;
    width: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    z-index: 99; }
    .mfp-content .modal-city div.suggestions ul, .mfp-content .white-popup-city div.suggestions ul {
      padding-top: 0;
      width: 100%;
      border: 1px solid #e1e1e1;
      border-top: none; }
      .mfp-content .modal-city div.suggestions ul li, .mfp-content .white-popup-city div.suggestions ul li {
        height: 30px;
        width: 100%;
        padding-left: 15px;
        box-sizing: border-box;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        color: #292929;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s; }
        .mfp-content .modal-city div.suggestions ul li:hover, .mfp-content .white-popup-city div.suggestions ul li:hover {
          background-color: #245eca; }
  .mfp-content .modal .success-form, .mfp-content .white-popup .success-form {
    font-size: 1.4375rem;
    font-family: "Montserrat", sans-serif;
    line-height: 1.75rem;
    color: #292929; }
  .mfp-content .modal.add-to-cart-modal, .mfp-content .white-popup.add-to-cart-modal {
    padding: 30px 35px;
    max-width: 400px;
    border-radius: 10px; }
    .mfp-content .modal.add-to-cart-modal h3, .mfp-content .white-popup.add-to-cart-modal h3 {
      margin-bottom: 0; }
  .mfp-content .modal .forget-password, .mfp-content .white-popup .forget-password {
    font-size: 18px;
    margin-top: 15px; }

.mfp-content .modal-tabs--link {
  margin-bottom: 10px; }
  .mfp-content .modal-tabs--link ul {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; }
    .mfp-content .modal-tabs--link ul li {
      margin-right: 20px;
      font-size: 1rem;
      font-weight: 600;
      color: #1C4A9F;
      cursor: pointer; }
      .mfp-content .modal-tabs--link ul li.active {
        color: #1C4A9F;
        text-decoration: underline; }

.mfp-content .modal-tabs--content {
  display: none; }
  .mfp-content .modal-tabs--content.active {
    display: block; }
  .mfp-content .modal-tabs--content .form-header {
    margin-bottom: 25px; }
  .mfp-content .modal-tabs--content label {
    position: relative;
    width: 100%;
    margin-bottom: 10px; }
    .mfp-content .modal-tabs--content label:last-of-type {
      margin-bottom: 0; }
    .mfp-content .modal-tabs--content label input:required ~ .title::after {
      content: '\00A0*';
      color: red; }
    .mfp-content .modal-tabs--content label input:focus ~ .title {
      opacity: 0;
      pointer-events: none; }
    .mfp-content .modal-tabs--content label input:not(:placeholder-shown) + .title {
      opacity: 0;
      pointer-events: none; }
    .mfp-content .modal-tabs--content label input:-webkit-autofill,
    .mfp-content .modal-tabs--content label input:-webkit-autofill:hover,
    .mfp-content .modal-tabs--content label input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 40rem #ffff inset; }
      .mfp-content .modal-tabs--content label input:-webkit-autofill:not(:placeholder-shown) + .title,
      .mfp-content .modal-tabs--content label input:-webkit-autofill:hover:not(:placeholder-shown) + .title,
      .mfp-content .modal-tabs--content label input:-webkit-autofill:focus:not(:placeholder-shown) + .title {
        opacity: 0;
        pointer-events: none; }
    .mfp-content .modal-tabs--content label .title {
      position: absolute;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #ffffff;
      left: 21px;
      top: 15px;
      font-family: "Source Sans Pro", Sans-Serif;
      font-size: 1rem;
      color: #333a40;
      pointer-events: none; }
      @media (max-width: 420px) {
        .mfp-content .modal-tabs--content label .title {
          top: 11px;
          left: 11px; } }

.no-close span.icon.close-popup {
  display: none; }

.wtfblock {
  width: 100%;
  position: relative; }

.revert-main-page {
  position: absolute;
  right: 10px;
  display: block;
  top: 10px;
  background-image: url(../images/sprite.png);
  background-position: -165px -148px;
  width: 27px;
  height: 27px;
  cursor: pointer; }

.modal-search {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh; }
  @media (max-width: 425px) {
    .modal-search {
      padding: 12px 16px !important; } }
  .modal-search .search__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; }
  .modal-search .close-popup {
    position: static !important;
    background-image: none !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 8px; }
    @media (max-width: 425px) {
      .modal-search .close-popup {
        width: 30px !important;
        height: 30px !important; } }
  .modal-search .search-input {
    margin-bottom: 0 !important;
    border-radius: 4px;
    border: 1px solid #E2E3E3;
    padding: 8px 12px !important;
    height: 36px !important;
    font-size: 16px; }
    .modal-search .search-input::placeholder {
      color: #D6D8D9; }
  .modal-search .search-btn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    right: 16px;
    top: 50%;
    transform: translateY(-50%); }
    .modal-search .search-btn svg path {
      stroke: #39B79C; }
  .modal-search .search-items {
    position: static !important;
    width: 100%;
    background-color: transparent;
    height: calc(100vh - 100px); }

/*------------------------------------*\
  # Path
\*------------------------------------*/
/*------------------------------------*\
    # Colors
    # http://chir.ag/projects/name-that-color
\*------------------------------------*/
/*------------------------------------*\
    # Basic typography
\*------------------------------------*/
/*------------------------------------*\
    # Wrapper
\*------------------------------------*/
/*------------------------------------*\
  # Print
\*------------------------------------*/
/*------------------------------------*\
  # Magnific-Popup settings
\*------------------------------------*/
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------------------------------------------*\
  # Hides an element visually while still allowing the content to be accessible,
    to assistive technology, e.g. screen readers. Passing `unhide` will reverse
    the affects of the hiding, which is handy for showing the element on focus,
    for example.
  # https://goo.gl/Vf1TGn
\*------------------------------------------------------------------------*/
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
/*------------------------------------*\
  # Placeholder
\*------------------------------------*/
.search {
  position: relative;
  z-index: 20;
  margin-right: 35px; }
  @media (max-width: 1240px) {
    .search {
      position: static; } }
  @media (max-width: 600px) {
    .search {
      margin-right: 10px;
      padding-top: calc(50vh - 20px); } }
  @media (max-width: 375px) {
    .search {
      margin-right: 5px; } }
  .search.error .search-icon {
    color: #ff0000;
    background-image: url(../images/search-icon-error.svg); }
  .search-error {
    font-size: 12px;
    color: #a9a5a5;
    text-transform: uppercase;
    width: 100%;
    background-color: #fff; }
    @media (max-width: 1240px) {
      .search-error {
        right: 130px; } }
    @media (max-width: 600px) {
      .search-error {
        right: 15px;
        width: 230px;
        font-size: 9px; } }
  .search-icon {
    width: 90px;
    height: 24px;
    background-image: url(../images/search-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    border: none;
    outline: none;
    background-color: transparent; }
    @media (max-width: 1240px) {
      .search-icon {
        width: 24px;
        background-position: left 0 bottom -3px; } }
    .search-icon:hover .search-name {
      opacity: 0.8; }
    .search-icon.active {
      opacity: 0.8; }
    .search-icon span {
      padding-left: 30px;
      text-align: left;
      display: block;
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      font-size: 0.8125rem;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer; }
      @media (max-width: 1240px) {
        .search-icon span {
          display: none; } }
  .search-wrapper {
    box-shadow: 4px 4px 8px rgba(56, 56, 56, 0.2);
    border-radius: 3px;
    position: absolute !important;
    top: 35px;
    left: -5px;
    display: none; }
    @media (max-width: 1240px) {
      .search-wrapper {
        right: 13px;
        left: auto; } }
    .search-wrapper::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      box-shadow: 0 0.5px 0.5px rgba(56, 56, 56, 0.2);
      transform: rotate(-135deg);
      position: absolute;
      top: -5px;
      left: 22px; }
      @media (max-width: 1240px) {
        .search-wrapper::after {
          left: auto;
          right: 30%; } }
      @media (max-width: 600px) {
        .search-wrapper::after {
          right: 80px; } }
  .search-input {
    color: #333a40;
    padding: 10px 20px;
    width: 430px;
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px !important; }
    @media (max-width: 600px) {
      .search-input {
        width: calc(100vw - 26px); } }
  .search-btn {
    padding: 10px 5px;
    text-transform: uppercase;
    background-color: transparent;
    color: #1C4A9F;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    position: absolute !important;
    top: 1px;
    right: 40px;
    border: none;
    outline: none;
    background-color: transparent; }
  .search-x {
    width: 24px;
    height: 24px;
    background-image: url(../images/x.svg);
    background-size: 100%;
    position: absolute;
    top: 7px;
    right: 10px;
    border: none;
    outline: none;
    background-color: transparent; }

.search-items {
  margin: 5px 0;
  position: absolute;
  width: 430px;
  background-color: #f8f8f8;
  top: 70px;
  left: -5px;
  padding: 0 10px 0 20px;
  overflow-y: auto; }
  @media (max-width: 1240px) {
    .search-items {
      right: 13px;
      left: auto; } }
  @media (max-width: 600px) {
    .search-items {
      top: 69px;
      width: calc(100vw - 26px);
      height: calc(50vh - 62px) !important; } }
  @media (max-width: 375px) {
    .search-items {
      width: 100%;
      padding: 0 5px;
      margin: 5px -15px; } }

.search_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;
  text-decoration: none;
  padding: 5px 0 5px 5px;
  flex-wrap: nowrap; }
  .search_item:hover {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
    text-decoration: none; }
  .search_item-img {
    width: 100px;
    margin-right: 10px;
    flex-shrink: 0; }
    @media (max-width: 425px) {
      .search_item-img {
        width: 80px; } }
    @media (max-width: 375px) {
      .search_item-img {
        width: 70px; } }
    .search_item-img img {
      width: 100%;
      display: block; }
  .search_item-title {
    color: #292929;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    width: 400px; }
    @media (max-width: 560px) {
      .search_item-title {
        font-size: 12px;
        margin-right: 10px; } }
  .search_item-price {
    color: #292929;
    min-width: 80px;
    font-size: 14px; }
    @media (max-width: 560px) {
      .search_item-price {
        font-size: 13px;
        min-width: 75px; } }

.search_ims-empty {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 10px; }
  .search_ims-empty span {
    font-size: 16px;
    font-weight: 600;
    text-align: center; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.5; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

@media print {
  @page {
    margin: 1cm; }
  body {
    background-color: #fff; } }
