.footer {
  background: #404040;
  padding: 48px 0 49px;
  @media (max-width: 1095px){
    padding: 48px 0 72px;
  }
  @media (max-width: 425px){
    padding: 32px 0 59px;
  }

  &-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 12px;
      font-weight: $font-weight--bold;
      font-size: rem(24);
      line-height: 1.3;
      color: #ffffff;
    }
    .text {
      margin-bottom: 20px;
      font-weight: $font-weight--bold;
      font-size: rem(16);
      line-height: 1.3;
      text-align: center;
      color: #ffffff;
    }
    .btn {
      border-radius: 8px;
      padding: 10px 16px;
      border: 1px solid lighten($link-color, 20%);
      color:lighten($link-color, 20%);
      font-weight: $font-weight--bold;
      font-size: 14px;
      line-height: 1.3;
      text-transform: uppercase;
      letter-spacing: 0.008em;
      @include transition();
      &:hover {
        text-decoration: none;
        background-color: lighten($link-color, 20%);
        color: #404040;
      }
    }
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    flex-wrap: wrap;
    gap: 16px;
    @media (max-width: 920px){

      padding-top: 60px;
    }
     @media (max-width: 660px){
       flex-direction: column;
       padding-bottom: 44px;
       padding-top: 40px;
     }
  }

  &-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 48px;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #c4c4c4;
    @media (max-width: 920px){
      flex-wrap: wrap;
    }
    @media (max-width: 660px){
      padding-top: 20px;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 420px){
      align-items: flex-start;
    }

    a {
      display: inline-block;
      color: lighten($link-color, 20%);
      margin-bottom: 6px;
    }

    p {
      margin-bottom: 11px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      text-align: left;
       @media (max-width: 660px){
        text-align: center;
      }
       @media (max-width: 420px){
        text-align: left;
      }
    }

    &__right {
      text-align: right;
      @media (max-width: 660px){
        text-align: center;
      }
      @media (max-width: 420px){
        text-align: left;
      }
    }
  }

  &-logo{
     @media (max-width: 660px){
       margin:0 auto 48px;
     }
  }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 920px){
      width: 100%;
      order: 1;
      margin: 20px auto 0;
    }
     @media (max-width: 660px){
       order: 0;
       margin-top: 0;
       margin-bottom: 40px;
       flex-direction: column;
     }


    &__item {
      padding: 0 10px;
      @media (max-width: 660px){
        padding: 0;
        margin-bottom: 16px;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 19px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.008em;
      color: #FFFFFF !important;
      @include transition();
      span {
        @include transition();
      }
      &:hover {
        text-decoration: none !important;
        //color: $green !important;
        color: lighten($link-color, 20%) !important;
        span{
          transform: translateY(-19px);
        }
      }
    }
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    
    @media (max-width: 660px){
      gap: 16px
    }

    a {

      &:last-of-type {
        margin-right: 0;
      }

      .icon {
        width: 40px;
        height: 40px;
        display: block;
        @include transition();
          @media (max-width: 660px){
            width: 32px;
            height: 32px;
       }
      }
    }
  }
}
