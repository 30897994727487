.header {
  width: 100%;
  height: auto;
  //position: fixed;
  @media (max-width: 1095px) {
    //position: sticky;
    //z-index: 100;
    //top: 0;
  }
  @media (max-width: 425px) {
    .wrapper {
      padding: 0 15px;
    }
  }

  //& + section, & + div, & + span {
  //  @media (max-width: 1095px) {
  //    margin-top: 64px;
  //  }
  //}

  &-top {
    padding: 9px 0;
    background-color: #404040;
    @media (max-width: 1095px) {
      position: absolute;
      display: none;
      z-index: 9;
      max-width: 640px;
      width: 100%;
      height: 100px;
      top: 0;
      left: 0;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      @media (max-width: 1095px) {
        align-items: flex-end;
      }
    }

    &-col {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      @media (max-width: 1095px) {
        width: 100%;
        height: 50px;
      }

      &.left {
        justify-content: flex-start;
        @media (max-width: 1095px) {
          justify-content: space-between;
        }

        & > div {
          //&.language {
          //  box-sizing: border-box;
          //  height: 100%;
          //  padding-left: 20px;
          //
          //  a {
          //    margin-right: 20px;
          //    display: inline-block;
          //    line-height: 50px;
          //    font: {
          //      family: $baseFonts;
          //      weight: $font-weight--lite;
          //      size: rem(13px);
          //    }
          //    text-transform: uppercase;
          //    color: #b2b2b2;
          //    letter-spacing: 1.3px;
          //
          //    &.active {
          //      font: {
          //        weight: $font-weight--bold;
          //      }
          //      color: $yellow;
          //    }
          //
          //    &:hover {
          //      color: darken($yellow, 5%);
          //      text-decoration: none;
          //    }
          //
          //    &:last-child {
          //      margin-right: 70px;
          //    }
          //  }
          //}

          &.city {
            margin-right: 50px;
            @media (max-width: 1095px) {
              margin-right: 0;
            }

            p {
              display: flex;
              align-items: center;

              .icon {
                margin-right: 16px;
              }

              color: #ffffff;
              letter-spacing: 1.4px;
              font: {
                size: rem(14px);
                weight: $font-weight--lite;
              }

              a {
                margin-left: 5px;
                letter-spacing: 1.4px;
                color: #ffffff;
                text-transform: uppercase;
                @include transition();
                font: {
                  weight: $font-weight--bold;
                  size: rem(14px);
                }
                border-bottom: 1px dashed #fff;

                &:hover {
                  text-decoration: none;
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }

      &.right {
        justify-content: flex-end;
        @media (max-width: 1095px) {
          display: none;
        }

        & > div {
          display: flex;
          align-items: center;

          &.phone {
            margin-right: 20px;

            .phone_link {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              height: 1rem;

              &:hover {
                opacity: 0.8;

                span {
                  // transform: translateY(-100%);
                }
              }

              span {
                height: 100%;
                text-align: center;
                @include transition();
              }
            }

            p {
              margin-right: 20px;

              a {
                color: #fff;
                font: {
                  size: rem(13px);
                  weight: $font-weight--regular;
                }
                text-transform: uppercase;
                @include transition();

                &:hover {
                  text-decoration: none;
                  opacity: 0.8;
                }
              }
            }

            .header-btn {
              span {
                color: #fff;
                font: {
                  size: rem(13px);
                  weight: $font-weight--regular;
                }
                text-transform: uppercase;
                @include transition();
              }

              &:hover {
                text-decoration: none;

                span {
                  opacity: 0.8;
                }
              }
            }
          }

          &.link {
            a {
              display: inline-block;
              color: #ffffff;

              font: {
                size: rem(13px);
                weight: $font-weight--regular;
              }
              line-height: 32px;
              text-transform: uppercase;

              &:hover {
                text-decoration: none;
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}

.header-bottom {
  width: 100%;
  //height: 80px;
  position: sticky;
  top: 0;
  z-index: 1000;
  //background-color: $body-bg-color;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 1096px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1095px) {
    height: auto;
    padding: 17px 0;
    background-color: $white;
  }
  @media (max-width: 425px) {
    height: auto;
    padding: 14px 0;
    background-color: $white;
  }

  .wrapper {
    width: 100%;
  }

  .header-wrapper {
    background-color: $white;
    @include transition();
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1095px) {
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 1095px) {
      flex-wrap: wrap;
      //padding-top: 20px;
      align-items: center;
    }
    @media (max-width: 425px) {
      padding: 0 15px !important;
    }
  }

  .logo {
    width: 98px;
    display: flex;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
    right: auto;
    @media (max-width: 1095px) {
      width: 82px;
    }

    .header-logo-white {
      display: none;
    }

    .header-logo-dark {
      display: flex;
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 100%;
      }
    }
  }

  .personal-link {
    display: flex;
    align-items: center;
    margin: 0;
    padding-right: 16px;
    @media (max-width: 1095px) {
      display: none !important;
    }

    .personal-link {
      margin-right: 35px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 600px) {
        margin-right: 10px;
      }
      @media (max-width: 375px) {
        margin-right: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;

        &.icon {
          position: relative;
          display: block;
          flex-shrink: 0;
        }

        .coutn-personal {
          position: absolute;
          width: 5px;
          height: 5px;
          display: block;
          background-color: $green;
          border-radius: 50%;
          top: 0;
          right: 0;
        }

        &.title {
          color: #333a40;
          @include transition();
          font: {
            size: rem(13px);
            weight: $font-weight--semibold;
          }
          margin-left: 14px;
          text-transform: uppercase;
          flex-shrink: 0;
          @media (max-width: 1240px) {
            display: none;
          }
        }
      }

      &:hover {
        text-decoration: none;

        .title {
          opacity: 0.8;
        }
      }
    }

    .basket-link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      //margin-right: 24px;
      @media (max-width: 1095px) {
        margin-right: 0px;
      }

      &:hover {
        text-decoration: none;

        .title {
          opacity: 0.8;
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        background-position: center center;
        background-repeat: no-repeat;
        //order: 1;
      }

      .title {
        color: #333a40;
        order: 3;
        font: {
          size: rem(13px);
          weight: $font-weight--semibold;
        }
        text-transform: uppercase;
        margin-left: 14px;
        flex-shrink: 0;
        @media (max-width: 1240px) {
          display: none;
        }
      }

      .coutn-basket {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        //order: 2;
        /* identical to box height, or 16px */

        text-align: center;
        color: #404040;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .socials {
    display: none;
    @media (max-width: 1095px) {
      display: flex;
    }

    &__link {
      display: block;
      width: 24px;
      height: 24px;
      margin-left: 16px;
      @media (max-width: 768px) {
        margin-left: 8px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

/*.fixed {
  position: fixed;
  max-width: 1920px;
  margin: 0 auto auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  background-color: $body-bg-color;
  width: 100%;
  box-shadow: 2px 2px 2px $gray;
  visibility: hidden;
  //@include transition();
  height: 80px;
  @media (max-width: 1095px) {
    display: none;
  }

  &-logo {
    height: 32px;
    width: auto;
    display: block;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    @media (max-width: 1095px) {
      width: 100%;
      order: 2;
      position: relative;
      top: auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: auto;
        height: 32px;
        display: block;
      }
    }
  }

  &-personal-link {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: {
      right: 0;
      left: auto;
    };
    @media (max-width: 1095px) {
      height: 30px;
    }

    .personal-link {
      margin-right: 10px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: inline-block;
        vertical-align: middle;

        &.icon {
          position: relative;
          display: block;
          flex-shrink: 0;
        }

        .coutn-personal {
          position: absolute;
          width: 5px;
          height: 5px;
          display: block;
          background-color: $green;
          border-radius: 50%;
          top: 0;
          right: 0;
        }

        &.title {
          color: #333a40;
          font: {
            size: rem(13px);
            weight: $font-weight--semibold;
          }
          margin-right: 14px;
          text-transform: uppercase;
          flex-shrink: 0;
          @media (max-width: 1240px) {
            display: none;
          }
        }
      }

      &:hover {
        text-decoration: none;

        .title {
          color: $link-color;
        }
      }
    }

    .basket-link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 24px;
      @media (max-width: 1095px) {
        margin-right: 0px;
      }

      &:hover {
        text-decoration: none;

        .title {
          color: $link-color;
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .title {
        color: #333a40;
        font: {
          size: rem(13px);
          weight: $font-weight--semibold;
        }
        margin-right: 14px;
        text-transform: uppercase;
        flex-shrink: 0;
        @media (max-width: 1240px) {
          display: none;
        }
      }

      .coutn-basket {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        !* identical to box height, or 16px *!

        text-align: center;
        color: #404040;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        left: calc(100% + 4px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-menu {
    width: 50%;

    .menu-btn {
      display: none;
      @media (max-width: 1095px) {
        display: block;
        z-index: 9999;
        position: relative;
      }

      &:hover {
        text-decoration: none;
      }

      &.active {
        span {
          &.burger {
            transform: rotate(90deg);
          }
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;

        &.burger {
          margin-right: 10px;
          position: relative;
          width: 20px;
          height: 2px;
          background-color: #A1E7D8;
          @include transition();

          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: #A1E7D8;
            transform: translateY(5px);
            bottom: 0;
            left: 0;
            z-index: 1;
          }

          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: #A1E7D8;
            transform: translateY(-5px);
            bottom: 0;
            left: 0;
            z-index: 1;
          }

        }

        &.info {
          font: {
            size: rem(16px);
            weight: $font-weight--bold;
          }
          color: $base-color;
          text-transform: uppercase;
        }
      }
    }

    & > ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 1095px) {
        display: none;
        position: absolute;
        top: 63px;
        left: 0;
        right: 0;
        max-width: 640px;
        z-index: 999;
        background-color: #fff;
        box-shadow: 1px 1px 1px rgba(41, 41, 41, .5);
        box-sizing: border-box;
        padding: 40px 20px 20px;

      }

      & > li {
        padding-left: 30px;
        box-sizing: border-box;
        margin-right: rem(40px);
        @media (min-width: 991px) {
          &:first-child {
            padding-left: 0;
          }
        }
        @media(max-width: 1095px) {
          margin: {
            right: 0px !important;
            left: 0px !important;
            bottom: 10px !important;
            top: 0px !important;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 1600px) {
          padding-left: 0;

        }
        @media (max-width: 1420px) {
          margin-right: 20px;
        }
        @media (max-width: 1290px) {
          &:first-child {
            padding-left: 0;
          }
        }

        & > span.show-dropdown {
          display: none;
          @include transition();
          @media (max-width: 1095px) {
            display: block;
            position: absolute;
            cursor: pointer;
            z-index: 40;
            right: 10px;
            margin-top: -34px;
            width: 40px;
            height: 40px;
            &:after {
              content: '';
              position: absolute;
              width: 14px;
              height: 14px;
              border: {
                top: 2px solid $green;
                right: 2px solid $green;
              };
              transform: rotate(45deg);
              top: 50%;
              left: 50%;
              margin-left: -7px;
              margin-top: -7px;
              @include transition();
            }

            &.active {
              transform: rotate(90deg);
            }
          }
        }

        & > a {
          color: #333a40;
          font: {
            size: rem(13px);
            weight: $font-weight--bold;
          }
          line-height: 21px;
          text-transform: uppercase;
          position: relative;


          &:hover {
            text-decoration: none;
            color: $link-color;

          }

        }

        &.dropdown {
          position: relative;

          &:hover {
            .submenu {
              //display: block;
              @media(max-width: 1095px) {
                display: none;
              }
            }
          }

          .submenu {
            display: none;
            position: absolute;
            width: 251px;
            box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
            background-color: #ffffff;
            box-sizing: border-box;
            padding: 10px 0;
            z-index: 99;
            @media (max-width: 1095px) {
              position: relative;
              width: 100%;
              box-shadow: none;
              padding: 0;
            }

            & > ul {
              width: 100%;

              & > li {
                min-height: 48px;
                @media (max-width: 1095px) {
                  min-height: 24px;
                }

                a {
                  padding: 14px 0;
                  line-height: 20px;
                  display: block;
                  font: {
                    family: $baseFonts;
                    weight: $font-weight--regular;
                    size: rem(13px);
                  }
                  color: #333a40;
                  @include transition();
                  padding: {
                    left: 30px;
                  };
                  @media (max-width: 1095px) {
                    line-height: 24px;
                    font-size: rem(18px);
                    padding-left: 5px;
                  }

                  &:hover {
                    background-color: #A1E7D8;
                    color: #fff;
                    font-weight: $font-weight--bold;
                    text-decoration: none;
                  }
                }

                !* &.sublink {
                   &:hover {
                     .subinfo {
                       display: flex;
                       flex-direction: column;
                       align-items: center;
                       justify-content: center;
                       @media(max-width: 1095px) {
                         display: none;
                       }
                     }
                   }


                  !* .subinfo {
                     display: none;
                     position: absolute;
                     border-left: 1px solid #ebebeb;
                     box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
                     box-sizing: border-box;
                     left: 100%;
                     top: 0;
                     width: 400px;
                     min-height: 100%;
                     background-color: #ffffff;

                     & > ul {
                       height: 100%;

                       & > li {
                         height: 100%;
                         display: flex;
                         flex-direction: column;
                         align-items: center;
                         padding: 0 rem(38px);

                         .img {
                           width: 130px;

                           a {
                             padding-left: 0;
                           }

                           a:hover {
                             background-color: transparent;
                             color: transparent;

                           }

                           img {
                             display: block;
                             width: 100%;
                           }
                         }

                         .title {
                           color: #8b8b8b;
                           font: {
                             family: $baseFonts;
                             size: rem(14px);
                             weight: $font-weight--regular;
                           }
                           line-height: 21px;
                           text-align: center;
                         }
                       }
                     }
                   }*!
                 }*!
              }
            }
          }
        }
      }

      & > p {
        display: none;
        @media (max-width: 1095px) {
          display: block;
          color: $gray;

          a {
            color: #A1E7D8;
            font-size: 1.3rem;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

}*/

.header-transparent {
  @media (min-width: 1095px) {
    .header-bottom {
      position: absolute;
      top: 50px;

      .header-wrapper {
        background: rgba(255, 255, 255, 0.06);
        @include transition();
      }

      .header-logo-white {
        display: block;
      }

      .header-logo-dark {
        display: none;
      }

      .main-menu__list {
        a {
          color: $white;
          @include transition();

          &:hover {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }

      .search-icon {
        background-image: url(../images/search-icon-white.svg);
        @include transition();

        &:hover {
          .search-name {
            opacity: 0.8;
          }
        }
      }

      .search-name {
        color: $white;
        @include transition();
      }

      .personal-link {
        .icon-personal {
          background-image: url(../images/icon-personal.svg);
          @include transition();
        }

        span.title {
          color: $white;
          @include transition();
        }

        .coutn-basket {
          color: $white;
          @include transition();
        }

        .icon-basket {
          background-image: url(../images/icon-basket.svg);
          @include transition();
        }
      }
    }
  }
}

.menu-mobile {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 16px 48px;
  background-color: $white;
  z-index: 30;
  justify-content: space-between;
  display: none;
  @media (max-width: 1095px) {
    display: flex;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    justify-content: space-around;
  }
  @media (max-width: 425px) {
    padding: 2px 16px;
  }

  &__item {
  }

  &__btn {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 8px;
    color: rgba(41, 41, 41, 0.7);
    font-weight: $font-weight--bold;
    cursor: pointer;

    &:hover {
      color: rgba(41, 41, 41, 0.7);
      text-decoration: none;
    }

    &:active {
      color: rgba(41, 41, 41, 0.7);
      text-decoration: none;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      font-size: 12px;
    }
    @media (max-width: 425px) {
      font-size: 10px;
    }

    svg {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 2px;
      }
    }
  }
}
