.mfp-bg {
  position: fixed !important;
  height: 100% !important;
}

.mfp-container {
  padding: 0 !important;
}

.mfp-wrap {
  overflow: hidden auto;
  position: fixed !important;
  height: 100% !important;
  top: 0 !important;
}

.mfp-content {
  .modal, .white-popup {
    max-width: 580px;
    background-color: #fff;
    width: 100%;
    padding: 60px 65px;
    box-sizing: border-box;
    position: relative;
    margin: auto;
    @media (max-width: 1200px) {
      padding: 30px;
    }
    @media (max-width: 1200px) {
      max-width: 400px;
    }

    .close-popup {
      position: absolute;
      right: 10px;
      top: 10px;
      @include sprite($close);
      cursor: pointer;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .form-header {
      width: 100%;
      text-align: center;
      font-family: "Ossem-Rounded", Sans-Serif;
      font-size: rem(32px);
      font-weight: 400;
      margin-bottom: 55px;
      @media(max-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    label {
      display: block;
      width: 100%;
      position: relative;
    }

    input[type="text"], input[type="email"], input[type="password"] {
      width: 100%;
      font-size: rem(16px);
      color: #333a40;
      padding: 0 20px;
      height: 48px;
      line-height: 48px;
      border: 1px solid #cbd7d5;
      font-family: "Source Sans Pro", Sans-Serif;
      margin-bottom: 10px;
      @media (max-width: 420px) {
        height: 38px;
        line-height: 30px;
        margin-bottom: 10px;
        padding: 0 10px;
      }
    }

    textarea {
      width: 100%;
      height: 150px;
      font-size: rem(16px);
      color: #333a40;
      padding: 20px;
      border: 1px solid #cbd7d5;
      font-family: "Source Sans Pro", Sans-Serif;
      margin-bottom: 10px;
      resize: none;
      @media (max-width: 420px) {
        height: 70px;
        padding: 5px 10px;
      }
    }

    .rules {
      font-size: 12px;
      color: #acacac;
      padding-left: 45px;
      position: relative;
      cursor: pointer;

      input {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 1px;
        height: 1px;
        opacity: 0 !important;
        z-index: -1;

        &:checked + .accept {
          display: block;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        display: block;
        background-color: $green;
        border-radius: 4px;
      }

      .accept {
        position: absolute;
        top: 7px;
        left: 5px;
        width: 12px;
        height: 10px;
        display: none;
        background-image: url("../images/vector/check.svg");
        background-repeat: no-repeat;
      }
    }

    input[type="submit"] {
      width: 100%;
      max-width: 340px;
      height: 50px;
      line-height: 50px;
      text-transform: uppercase;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border: 0;
      border-radius: 8px;
      background-color: $green;
      margin-top: 40px;
      @include transition();

      &:hover {
        background-color: lighten($green, 5%);
      }

      &:active {
        background-color: $green;
      }
    }

    .mfp-close {
      display: none;
    }

    &-city {
      .form-header {
        margin-bottom: 25px;

        span {
          color: $green;
        }

        p {
          font: {
            family: $baseFonts;
            size: rem(14px);
            weight: $font-weight--regular;
          }
          line-height: rem(21px);
          color: $gray;
        }
      }

      label {
        position: relative;
        height: 48px;
      }

      div.suggestions {
        position: absolute;
        top: 100%;
        background-color: #fff;
        width: 100%;
        left: 0;
        right: 0;
        max-height: 300px;
        overflow-y: auto;
        z-index: 99;

        ul {
          padding-top: 0;
          width: 100%;
          border: 1px solid #e1e1e1;
          border-top: none;

          li {
            height: 30px;
            width: 100%;
            padding-left: 15px;
            box-sizing: border-box;
            line-height: 30px;
            font: {
              family: $baseFonts;
              size: rem(16px);
            }
            color: $base-color;
            cursor: pointer;
            @include transition();

            &:hover {
              background-color: lighten($green, 10%);
            }

            //&:first-child{
            //  margin-top: 5px;
            //}
          }
        }
      }
    }

    .success-form {
      font: {
        size: rem(23px);
        family: $ossem;
      }
      line-height: rem(28px);
      color: $base-color;
    }

    &.add-to-cart-modal {
      padding: 30px 35px;
      max-width: 400px;
      border-radius: 10px;

      h3 {
        margin-bottom: 0;
      }
    }

    .forget-password {
      font-size: 18px;
      margin-top: 15px;
    }

  }


  .modal-tabs {
    &--link {
      margin-bottom: 10px;

      ul {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        li {
          margin-right: 20px;
          font: {
            size: 1rem;
            weight: $font-weight--semibold;
          }
          color: $yellow;
          cursor: pointer;

          &.active {
            color: $green;
            text-decoration: underline;
          }
        }
      }
    }

    &--content {
      display: none;

      &.active {
        display: block;
      }

      .form-header {
        margin-bottom: 25px;
      }

      label {
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        input {

          &:required {
            & ~ .title {
              &::after {
                content: '\00A0*';
                color: red;
              }
            }
          }

          &:focus {

            & ~ .title {
              opacity: 0;
              pointer-events: none;
            }
          }

          &:not(:placeholder-shown) + .title {
            opacity: 0;
            pointer-events: none;

          }

        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
          &:not(:placeholder-shown) + .title {
            opacity: 0;
            pointer-events: none;

          }
        }

        .title {
          position: absolute;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: #ffffff;
          left: 21px;
          top: 15px;
          font-family: "Source Sans Pro", Sans-Serif;
          font-size: 1rem;
          color: #333a40;
          pointer-events: none;

          @media (max-width: 420px) {
            top: 11px;
            left: 11px;
          }
        }
      }
    }
  }
}

.no-close {
  span.icon.close-popup {
    display: none;
  }
}

.wtfblock {
  width: 100%;
  position: relative;
}

.revert-main-page {
  position: absolute;
  right: 10px;
  display: block;
  top: 10px;
  @include sprite($close);
  cursor: pointer;
}


.modal-search {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh;
  @media (max-width: 425px) {
    padding: 12px 16px !important;
  }

  .search__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .close-popup {
    position: static !important;
    background-image: none !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 8px;
    @media (max-width: 425px) {
      width: 30px !important;
      height: 30px !important;
    }
  }

  .search-input {
    margin-bottom: 0 !important;
    border-radius: 4px;
    border: 1px solid #E2E3E3;
    padding: 8px 12px !important;
    height: 36px !important;
    font-size: 16px;

    &::placeholder {
      color: #D6D8D9;
    }
  }

  .search-btn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      path {
        stroke: #39B79C;
      }
    }
  }

  .search-items {
    position: static !important;
    width: 100%;
    background-color: transparent;
    height: calc(100vh - 100px);
  }
}