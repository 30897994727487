/* montserrat-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin_cyrillic */
/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v24-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v24-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v24-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v24-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v24-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v24-latin_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v24-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v24-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v24-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v24-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v24-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v24-latin_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/montserrat-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}