.main-menu {
  width: auto;
  margin: {
    left: 0;
    right: auto;
  }
  @media (max-width: 1095px) {
    order: 0;
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 30%;
  }

  &-hide {
    display: none;
    @media (max-width: 1095px) {
      display: block;
    }
  }

  .menu-btn {
    display: none;
    @media (max-width: 1095px) {
      display: block;
      z-index: 9999;
      position: relative;
    }

    &:hover {
      text-decoration: none;
    }

    &.active {
      span {
        &.burger {
          transform: rotate(-45deg);
          background-color: #d6d8d9;

          &:after {
            transform: rotate(90deg);
            background-color: #d6d8d9;
          }

          &:before {
            background-color: #d6d8d9;
            transform: scale(0);
          }
        }

        &.info {
          //color: #fff;
        }
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;

      &.burger {
        margin-right: 10px;
        position: relative;
        width: 21px;
        height: 2px;
        background-color: $link-color;
        @include transition();

        @media (max-width: 375px) {
          margin-right: 5px;
        }

        &:after {
          position: absolute;
          content: "";
          display: block;
          width: 21px;
          height: 2px;
          background-color: $link-color;
          transform: translateY(6px);
          bottom: 0;
          left: 0;
          z-index: 1;
        }

        &:before {
          position: absolute;
          content: "";
          display: block;
          width: 21px;
          height: 2px;
          background-color: $link-color;
          transform: translateY(-6px);
          bottom: 0;
          left: 0;
          z-index: 1;
        }
      }

      &.info {
        font: {
          size: rem(16px);
          weight: $font-weight--bold;
        }
        color: $base-color;
        text-transform: uppercase;
        @media (max-width: 425px) {
          display: none;
        }
      }
    }
  }

  &__content {
    @media (max-width: 1095px) {
      display: none;
      padding: 0 0 8px;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      z-index: 999;
      width: 100vw;
      background-color: #fff;
      box-shadow: 1px 10px 10px rgba(41, 41, 41, 0.2);
      box-sizing: border-box;
      //height: calc(100vh - 58px);
      height:calc(var(--vh,1vh) * 100 - 58px);
      overflow-y: auto;
    }
    @media (max-width: 425px) {
      top: 36px;
      //height: calc(100vh - 50px);
      height:calc(var(--vh,1vh) * 100 - 50px);
    }
  }


  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @media (max-width: 1095px) {
      flex-direction: column;
      align-items: flex-start;
      overflow: auto;
      height: 100%;
    }
    @media (min-width: 1096px) {
      display: flex;
    }

    .user {
      display: none;
      @media (max-width: 1095px) {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 16px;
        color: $base-color;
      }

      svg {
        path {
          stroke: #434444;
        }
      }
    }

    & > li {
      //padding-left: 30px;
      box-sizing: border-box;
      //margin-right: 32px;
      @media (min-width: 991px) {
        &:first-child {
          padding-left: 0;
        }
      }
      @media (max-width: 1095px) {
        width: 100%;
        margin: {
          right: 0px;
          left: 0px;
          bottom: 4px;
          top: 0px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 1600px) {
        padding-left: 0;
      }
      @media (max-width: 1420px) {
        //margin-right: 20px;
      }
      @media (max-width: 1366px) {
        // margin-right: 8px;
      }
      @media (max-width: 1290px) {
        &:first-child {
          padding-left: 0;
        }
      }

      a {
        padding: 26px 16px;
        color: #333a40;
        font-size: rem(14);
        font-weight: $font-weight--bold;
        text-transform: uppercase;
        line-height: 1.5;
        position: relative;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 25px;
        @include transition();

        &:hover {
          text-decoration: none;
          color: rgba(41, 41, 41, 0.7);
        }

        @media (max-width: 1540px) {
          padding: 24px 8px;
          font-size: rem(13);
        }

        @media (max-width: 1250px) {
          font-size: 11px;
          font-weight: $font-weight--regular;
        }
        @media (max-width: 1095px) {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          text-transform: none;
          padding: 16px;
        }

        img {
          display: none;
          @media (max-width: 1095px) {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }

      &.dropdown {
        position: relative;

        .dropdown-top {
          display: flex;
          position: relative;

          @media (max-width: 1095px) {
            width: 100%;

            &.active {
              background-color: $link-color;
              color: $white;

              a {
                color: $white;

                .mobile-active {
                  display: block;
                }

                .default {
                  display: none;
                }

                svg {
                  path {
                    stroke: $white;
                  }
                }
              }
            }
          }

          a {
            @media (max-width: 1095px) {
              width: 100%;
              padding: 16px;
              font-weight: 600;
              font-size: 18px;
              line-height: 26px;
            }

            svg {
              display: none;
              @media (max-width: 1095px) {
                display: block;
              }
            }

            .mobile-active {
              display: none;
            }
          }

          /* .show-dropdown {
             width: 100px;
             height: 100px;
             background-color: $body-bg-color;
             border-radius: 100% 0;
             position: absolute;
             top: 40px;
             left: 50%;
             transform: skew(20deg) rotate(40deg) translateX(-50%);
             display: none;
             //opacity: 0;
             // @include transition();

           }*/

          span.show-dropdown {
            display: none;
            @include transition();
            margin-left: 16px;
            @media (max-width: 1095px) {
              position: relative;
              //display: block;
              cursor: pointer;
              z-index: 40;
              width: 100%;

              &::before,
              &::after {
                @include transition();
                content: "";
                position: absolute;
                width: 10px;
                height: 2px;
                background: #333a40;
              }
              &::before {
                top: 16px;
                right: 0;
                transform-origin: 0 50%;
                transform: rotate(135deg);
              }
              &::after {
                top: 17px;
                right: 10px;
                transform-origin: 100% 50%;
                transform: rotate(45deg);
              }

              &.active {
                &::before,
                &::after {
                  width: 20px;
                }

                &::before {
                  top: 4px;
                  right: -9px;
                  //transform: rotate(45deg);
                }

                &::after {
                  top: 18px;
                  //right: 7px;
                  //transform: rotate(-45deg);
                }
              }
            }
          }
        }

        &:hover {
          .submenu {
            display: flex;
            @media (max-width: 1095px) {
              display: none;
            }
          }

          /*
           .show-dropdown {
             opacity: 1;
           }*/
        }

        .submenu {
          display: none;
          position: absolute;
          width: 558px;
          left: 0;
          top: 100%;
          background-color: $body-bg-color;
          box-sizing: border-box;
          // padding: 12px 0;
          z-index: 99;
          max-height: 350px;

          @media (max-width: 1095px) {
            position: absolute;
            width: calc(100% - 56px);
            right: 0;
            left: auto;
            box-shadow: none;
            display: none;
            background: #E9EDF6;
            max-height: none;
            top: 59px;
            //overflow: hidden;
          }

          /*&__models {
            overflow-x: hidden;
            overflow-y: auto;
            @include scrollbar;
            width: 308px;
            padding: 0 16px;
            background: $white;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            max-height: 100%;
            display: none;
            @media (max-width: 1095px) {
              width: 100%;
              position: static;
              max-height: none;
              background: #F8F8F8;
              padding: 0;

            }
          }*/

          &.visible {
            display: flex;
            @media (max-width: 1095px) {
              flex-direction: column;
            }
          }

          .categories {
            &-list {
              @include scrollbar;
              overflow-x: hidden;
              overflow-y: auto;
              height: 100%;
              max-height: 350px;
              @media (max-width: 1095px) {
                width: 100%;
                max-height: none;
              }
            }
          }

          & > ul {
            width: 250px;
            display: flex;
            flex-direction: column;
            @media (max-width: 1095px) {
              width: 45%;
            }

            & > li {
              /* min-height: 48px;
               @media (max-width: 1095px) {
                 min-height: 24px;
               }*/

              a {
                padding: 13px 0;
                text-transform: none;
                display: flex;
                align-items: center;
                font-size: rem(14);
                line-height: 1.5;
                font-weight: $font-weight--bold;
                text-transform: uppercase;
                color: #333a40;
                @include transition();
                padding: {
                  left: 16px;
                }
                @media (max-width: 1095px) {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  text-transform: none;
                  padding: 16px
                }

                span {
                  svg {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                    display: none;
                    @media (max-width: 1095px) {
                      display: block;
                    }
                  }
                }

                &:hover {
                  //background-color: #A1E7D8;
                  //color: #A1E7D8;
                  opacity: 0.8;
                  text-decoration: none;

                  span {
                    svg {
                      path {
                        @include transition();
                        fill: $white;
                      }
                    }
                  }
                }

                @media (max-width: 1095px) {
                  &:hover {
                    color: $base-color;
                  }
                }
              }

              &.sublink {
                padding-right: 16px;

                @media (max-width: 1540px) {
                  padding-right: 8px;
                }
                @media (max-width: 1095px) {
                  padding-right: 0;
                }

                .sublink__head {
                  @media (max-width: 1095px) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }

                .categories-list__item-link {
                  font-size: rem(14);
                  line-height: 1.5;
                  font-weight: $font-weight--bold;
                  text-transform: uppercase;
                  white-space: normal;
                  display: block;
                  @media (max-width: 1540px) {
                    font-size: rem(12);
                  }
                  @media (max-width: 1095px) {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: none;
                    padding: 16px;
                    width: 70%;
                  }
                }

                .categories-list__svg-link {
                  width: 20%;
                  height: 56px;
                  display: none;
                  @media (max-width: 1095px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background-color: transparent;
                  }

                  &.active {
                    svg {
                      transform: rotate(-90deg);
                    }
                  }
                }

                svg {
                  display: none;
                  @media (max-width: 1095px) {
                    display: block;
                    flex-shrink: 0;
                    width: 20px;
                    transform: rotate(90deg);
                    @include transition();
                  }
                }

                /*
                                &:hover {
                                  .subinfo {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    @media(max-width: 1095px) {
                                      display: none;
                                    }
                                  }
                                }

                                .subinfo {
                                  display: none;
                                  position: absolute;
                                  border-left: 1px solid #ebebeb;
                                  box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
                                  box-sizing: border-box;
                                  left: 100%;
                                  top: 0;
                                  width: 400px;
                                  min-height: 100%;
                                  background-color: #ffffff;

                                  & > ul {
                                    height: 100%;

                                    & > li {
                                      height: 100%;
                                      display: flex;
                                      flex-direction: column;
                                      align-items: center;
                                      padding: 0 rem(38px);

                                      .img {
                                        width: 130px;

                                        a {
                                          padding-left: 0;
                                        }

                                        a:hover {
                                          background-color: transparent;
                                          color: transparent;

                                        }

                                        img {
                                          display: block;
                                          width: 100%;
                                        }
                                      }

                                      .title {
                                        color: #8b8b8b;
                                        font: {
                                          family: $baseFonts;
                                          size: rem(14px);
                                          weight: $font-weight--regular;
                                        }
                                        line-height: 21px;
                                        text-align: center;
                                      }
                                    }
                                  }
                                }*/
              }
            }
          }

          .models {
            /*  &-nav {
                display: none;

                &.active {
                  display: block;
                }
              }*/
            &-nav {
              overflow-x: hidden;
              overflow-y: auto;
              @include scrollbar;
              width: 308px;
              padding: 0 16px;
              background: $white;
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              max-height: 100%;
              display: none;
              @media (max-width: 1095px) {
                width: 100%;
                position: static;
                max-height: none;
                background: #F8F8F8;
                padding: 0;

              }

              &.active {
                display: flex;
                @media (max-width: 1095px) {
                  flex-direction: column;
                }

              }
            }


            &-list {
              &__item {
                &-link {
                  padding: 13px 0 13px 16px;
                  white-space: normal;

                  @media (max-width: 1095px) {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .phone {
      display: none;
      @media (max-width: 1095px) {
        display: block;
        color: $base-color;
        font-size: 18px;
        padding: 8px 16px;
        text-decoration: underline;
        margin-top: auto;
      }
    }

    .socials {
      display: none;
      @media (max-width: 1095px) {
        display: flex;
        padding: 16px;
      }

      &__link {
        margin: 0 16px 0 0;
        width: 40px;
        height: 40px;
        display: block;
        @media (max-width: 660px){
          width: 32px;
          height: 32px;
        }

        span{
          width: 40px;
          height: 40px;
          transition: all 0.2s ease-in-out 0s;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100%;

          @media (max-width: 660px){
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &__close {
    display: none;
    @media (max-width: 1095px) {
      display: block;
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  &__close-btn {
    position: relative;
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background: $link-color;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
